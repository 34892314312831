// -*- mode: RJSX; js-indent-level: 2; -*-

import { useRef, useEffect } from 'react';
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import L from 'leaflet';

const useStyles = makeStyles(() => ({
  button: {
    boxShadow: '3px 3px 10px #444',
    border: '2.5px solid #444',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    "&:hover, &.Mui-focusVisible": {
      backgroundColor: 'rgba(225, 225, 225, 1)',
    },
  },
}));

const MapButton = ({children, ...props}) => {
  const ref = useRef();
  const classes = useStyles();

  useEffect(() => {
    if (ref.current) {
      L.DomEvent.disableClickPropagation(ref.current);
    }
  });

  return (
    <IconButton
        ref={ref}
        className={classes.button}
        {...props}>
      {children}
    </IconButton>
  );
};

export default MapButton;

// -*- mode: RJSX; js-indent-level: 2; -*-

import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { logout } from './user';
import { createApiAsyncThunk, tangerHttp } from './api';

const mapRefreshTime = 10*60*1000; // 10 minutes

export const useMapLayers = (lang) => useSelector((state) => [
  state.layers.mapList,
  state.layers.language !== lang ||
    !state.layers.timestamp ||
    state.layers.timestamp < new Date().getTime() - mapRefreshTime
]);

export const useRegionData = () => useSelector((state) => [
  state.layers.regionData,
  state.layers.lastRegionCenter,
  state.layers.lastRegionZ,
]);

export const useSelectedLayers = () => useSelector((state) => ({
  baseMapId: state.layers.active.baseMap,
  overlayIds: state.layers.active.overlays,
}));

export const refreshMapList = createApiAsyncThunk('layers/refresh', null, {
  url: (lang) => `/api/v1/maplist/${lang ?? 'en'}`,
});

export const refreshRegionData = createAsyncThunk('region/refresh', async ({center, z, token, lang}, api) => {
  try {
    const resp = await axios.post(tangerHttp+'/api/v2/maps/region', {
      token: token,
      latitude: center.lat,
      longitude: center.lon,
      z:z,
      lang: lang
    });
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      return resp.data;
    }
    return null;
  } catch(e) {
    return null;
  }
});

const clean = {
  timestamp: null,
  language: null,
  mapList: null,
  regionData: null,
  lastRegionCenter: null,
  lastRegionZ: null,
  workingOnList: false,
  workingOnRegion: false,
  active: {
    baseMap: window.localStorage.getItem('activeBaseMap'),
    overlays: (window.localStorage.getItem('activeOverlays') || '').split('\n').filter((i) => i),
  },
};

const postprocessOverlays = (list, min_z, max_z) => list.map((spec) => {
  const minz = spec.min_z || min_z;
  const maxz = spec.max_z || max_z;
  return {
    ...spec,
    ...(!minz ? {} : {min_z: minz}),
    ...(!maxz ? {} : {max_z: maxz}),
    ...(!spec.flavors ? {} : {
      flavors: postprocessOverlays(spec.flavors, minz, maxz),
    }),
  };
});

const postprocessMaps = (maps) => ({
  ...maps,
  overlay_maps: postprocessOverlays(maps.overlay_maps),
});

export const layersSlice = createSlice({
  name: 'layers',
  initialState: clean,
  reducers: {
    setActiveBaseMap: (state, action) => {
      state.active.baseMap = action.payload;
      window.localStorage.setItem('activeBaseMap', action.payload);
    },
    setActiveOverlays: (state, action) => {
      const {remove = [], add} = action.payload;
      const filtered = state.active.overlays.filter((i) => remove.indexOf(i) < 0);
      state.active.overlays = add ? [...filtered, add] : filtered;
      window.localStorage.setItem('activeOverlays', state.active.overlays.join('\n'));
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(refreshMapList.pending, (state, action) => ({...state, workingOnList: true}))
      .addCase(refreshMapList.fulfilled, (state, action) => (state.workingOnList ? {
        ...state,
        timestamp: new Date().getTime(),
        language: action.meta.arg,
        mapList: postprocessMaps(action.payload),
        workingOnList: false,
        lastRegionCenter: null,
      } : state))
      .addCase(refreshRegionData.pending, (state, action) => ({...state, workingOnRegion: true}))
      .addCase(refreshRegionData.fulfilled, (state, action) => (state.workingOnRegion ? {
        ...state,
        regionData: action.payload,
        lastRegionCenter: action.meta.arg.center,
        lastRegionZ: action.meta.arg.center,
      } : state))
      .addCase(logout, (state, action) => clean)
      .addDefaultCase((state, action) => {});
  }
});

export const { setActiveBaseMap, setActiveOverlays } = layersSlice.actions;
export default layersSlice.reducer;

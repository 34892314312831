// -*- mode: RJSX; js-indent-level: 2; -*-

import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  ListSubheader,
  Box,
  Avatar,
  Radio,
  Switch,
  IconButton,
  Collapse,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Trans, useTranslation } from 'react-i18next';
import clsx from 'clsx';
import L from 'leaflet';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { usePrevious } from '../util';
import { useRegionData, refreshRegionData } from '../state/layers.js';

const useStyles = makeStyles((theme) => ({
  content: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
  },
  layerRow: {
    padding: '0.25em 1em',
  },
  avatar: {
    backgroundColor: '#ddd',
    color: '#393920',
  },
  layerTitle: {
    paddingLeft: '1em',
    paddingRight: '1em',
  },
  expand: {
    padding: '8px',
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expanded: {
    transform: 'rotate(180deg)',
  },
}));

const collectExclusionList = (spec) => [
  spec.id,
  ...[].concat.apply([], (spec.flavors || []).map((f) => collectExclusionList(f)))
];

const anyChildActive = (spec, ids) =>
      ids.indexOf(spec.id) >= 0 ||
      !!(spec.flavors || []).find((f) => anyChildActive(f, ids));

const LayerToggle = ({
  base,
  mapList,
  layers,
  spec,
  regionData,
  exclude,
  indent = 0,
  setActiveBaseMap,
  setActiveOverlays,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(null);

  if (!base && spec.regions && regionData && spec.regions.indexOf(regionData.region) < 0) {
    return null;
  }

  if (!base) {
    if (spec.flavors_not_exclusive) {
      exclude = [];
    } else if (!exclude) {
      exclude = collectExclusionList(spec);
    }
  }

  if (expanded === null && spec.flavors?.length) {
    // initially expand if any child is active
    setExpanded(anyChildActive(spec, layers.overlayIds));
  }

  const fallbackBaseMap = base && (
    mapList.base_maps.map((f) => f.id).find((id) => id === layers.baseMapId) ??
      mapList.base_maps[0].id);

  const thumb = base && regionData?.base_maps ?
        regionData.base_maps[spec.id]?.thumbnail ?? spec.thumbnail :
        spec.thumbnail;
  return (
    <div style={{ paddingLeft: `${indent}em` }}>
      <Box display='flex' flexDirection='row' className={classes.layerRow}>
        <Box alignSelf='center'>
          <Avatar className={classes.avatar} src={thumb}>
            {!thumb && spec.name.slice(0, 2).toUpperCase()}
          </Avatar>
        </Box>
        <Box flexGrow={1} alignSelf='center' className={classes.layerTitle}>
          <div>
            <Typography variant='body1'>
              {spec.name}
            </Typography>
            {
              base && regionData?.base_maps && regionData?.base_maps[spec.id]?.effective_flavor !== spec.id &&
              <Typography variant='body2'>
                <Trans>layer-not-available</Trans>
              </Typography>
            }
          </div>
        </Box>
        <Box alignSelf='center'>
          {
            base ?
            <Radio
                checked={fallbackBaseMap === spec.id}
                onChange={() => setActiveBaseMap(spec.id)}/> :
            spec.url ?
            <Switch
                checked={layers.overlayIds.indexOf(spec.id) >= 0}
                onChange={() => {
                  const turnOn = layers.overlayIds.indexOf(spec.id) < 0;
                  if (turnOn) {
                    setActiveOverlays({
                      remove: exclude,
                      add: spec.id,
                    });
                  } else {
                    setActiveOverlays({
                      remove: spec.id,
                    });
                  }
                }}/> :
            spec.flavors ?
            <IconButton
                className={clsx(classes.expand, {[classes.expanded]: expanded})}
                onClick={() => setExpanded(!expanded)}>
              <ExpandMoreIcon/>
            </IconButton> :
            null
          }
        </Box>
      </Box>
      {
        spec.flavors &&
        <Collapse in={expanded} timeout='auto'>
          {spec.flavors.map((f) => (
            <LayerToggle
                key={f.id}
                indent={indent + 1}
                exclude={exclude}
                layers={layers}
                spec={f}
                regionData={regionData}
                setActiveOverlays={setActiveOverlays} />
          ))}
        </Collapse>
      }
    </div>
  );
};

const distance = (a, b) => L.latLng(a.lat, a.lon).distanceTo(L.latLng(b.lat, b.lon));

const MapLayerDialog = ({
  mapList,
  open,
  map,
  regionalToken,
  layers,
  setActiveBaseMap,
  setActiveOverlays,
  ...props
}) => {
  const classes = useStyles();
  const [regionData, lastRegionCenter, lastRegionZ] = useRegionData();
  const wasOpen = usePrevious(open && !!map);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (open && !!map && !wasOpen) {
      const c = map.getCenter();
      const ctx = {
        lang: t('lang-id'),
        center: {lat: c.lat, lon: c.lng},
        z: map.getZoom(),
        token: mapList.regional_token,
      };
      if (!lastRegionCenter || !lastRegionZ ||
          distance(lastRegionCenter, ctx.center) > 1000 ||
          Math.abs(lastRegionZ - ctx.z) >= 1) {
        dispatch(refreshRegionData(ctx));
      }
    }
  }, [open, wasOpen, map, mapList, lastRegionCenter, lastRegionZ, dispatch, t]);

  return (
    <Dialog open={open} {...props}>
      <DialogTitle>
        <Trans>layers-title</Trans>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <ListSubheader>
          <Trans>base-maps</Trans>
        </ListSubheader>
        {
          mapList.base_maps.map((f) => (
            <LayerToggle
                base
                key={f.id}
                mapList={mapList}
                layers={layers}
                spec={f}
                regionData={regionData}
                setActiveBaseMap={setActiveBaseMap} />
          ))
        }
        <ListSubheader>
          <Trans>overlays</Trans>
        </ListSubheader>
        {
          mapList.overlay_maps.map((f) => (
            <LayerToggle
                key={f.id}
                layers={layers}
                spec={f}
                regionData={regionData}
                setActiveOverlays={setActiveOverlays} />
          ))
        }
      </DialogContent>
    </Dialog>
  );
};

export default MapLayerDialog;

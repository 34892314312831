// -*- mode: RJSX; js-indent-level: 2; -*-

import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Fab,
} from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import DoneIcon from '@material-ui/icons/Done';
import DeleteIcon from '@material-ui/icons/Delete';
import { Hollow as AddAreaIcon, Solid as AddLineIcon } from '../icons/TargetStyleIcons';
import { ScaledIcon } from '../icons/TargetStyles';
import DrawerTabPanel from './DrawerTabPanel';
import { useUndo } from './Undo';
import {
  useFocused,
  setInstructionsSnack,
  useAddingMarkup,
  requestAdding,
  useMarkupDefaults,
} from '../state/ui';
import { deleteMarkup, resurrectMarkup, nextZ } from '../state/markup';

const useStyles = makeStyles((theme) => ({
  instructions: {
    padding: '1em',
    paddingTop: 0,
    '&:first-child': {
      paddingTop: '1em',
    },
  },
  card: {
    marginBottom: '1em',
  },
}));

const AreaMarkup = ({map, design, tracking, onDragEnd}) => {
  const classes = useStyles();
  const list = design.contents?.area ?? [];
  const dispatch = useDispatch();
  const focused = useFocused();
  const [multiSelect, setMultiSelect] = useState(null);
  const [setUndo] = useUndo();
  const adding = useAddingMarkup();
  const lineDefaults = useMarkupDefaults('line');
  const areaDefaults = useMarkupDefaults('area');
  const { t } = useTranslation();

  useEffect(() => {
    if (multiSelect) {
      for (const k in multiSelect) {
        if (multiSelect[k]) {
          return;
        }
      }
      setMultiSelect(null);
    }
  }, [multiSelect, focused, dispatch]);

  return (
    <>
      <DrawerTabPanel
          className={classes.tabPanel}
          tracking={tracking}
          design={design}
          onDeactivate={() => setMultiSelect(null)}
          fab={[
            {
              key: 'area',
              position: 0,
              visible: !adding.type && !focused?.markup && !multiSelect,
              fab: (
                <Fab
                    color='secondary'
                    onClick={(ev) => {
                      dispatch(requestAdding('area', {
                        type: 'Feature',
                        geometry: {
                          type: 'Polygon',
                        },
                        properties: {
                          ...areaDefaults,
                          id: 'new',
                          visible: true,
                          burn: true,
                          z: nextZ(list, 'area', false),
                        }
                      }));
                      dispatch(setInstructionsSnack(t('area-new-snack'), 'area/new'));
                    }}>
                  <ScaledIcon size={32}>
                    <AddAreaIcon color='white'/>
                  </ScaledIcon>
                </Fab>
              ),
            },
            {
              key: 'line',
              position: 1,
              visible: !adding.type && !focused?.markup && !multiSelect,
              fab: (
                <Fab
                    color='secondary'
                    onClick={(ev) => {
                      dispatch(requestAdding('line', {
                        type: 'Feature',
                        geometry: {
                          type: 'LineString',
                        },
                        properties: {
                          ...lineDefaults,
                          id: 'new',
                          visible: true,
                          burn: true,
                          z: nextZ(list, 'area', false),
                        }
                      }));
                      dispatch(setInstructionsSnack(t('line-new-snack'), 'line/new'));
                    }}>
                  <ScaledIcon size={32}>
                    <AddLineIcon color='white'/>
                  </ScaledIcon>
                </Fab>
              ),
            },
            {
              key: 'msdone',
              position: 0,
              visible: !!multiSelect,
              fab: (
                <Fab color='secondary' onClick={(ev) => {
                  setMultiSelect(null);
                }}>
                  <DoneIcon/>
                </Fab>
              ),
            },
            {
              key: 'trash',
              position: 1,
              visible: !!multiSelect,
              fab: (
                <Fab color='secondary' onClick={(ev) => {
                  const fwd = [];
                  const bwd = [];
                  let name;
                  for (const k in multiSelect) {
                    if (multiSelect[k]) {
                      const m = design.contents.lookup[k];
                      if (m && !m.properties.linkedTo) {
                        name = m.properties.name || `(${t('no-name')})`;
                        fwd.push(deleteMarkup(m, tracking));
                        bwd.push(resurrectMarkup(m, tracking));
                      }
                    }
                  }
                  if (fwd.length) {
                    setUndo(t('undo-delete-markup'), fwd, bwd, dispatch,
                            t('undo-hint-multi-deleted', {count: fwd.length, name}));
                  }
                  setMultiSelect(null);
                }}>
                  <DeleteIcon/>
                </Fab>
              ),
            },
          ]}
          instructions={(
            <>
              <Typography variant='body1' className={classes.instructions}>
                <Trans>area-instructions-1</Trans>
              </Typography>
              <Typography variant='body1' className={classes.instructions}>
                <Trans>area-instructions-2</Trans>
              </Typography>
              {
                !list.length &&
                  <Typography variant='body1' className={classes.instructions}>
                    <Trans>area-list-empty</Trans>
                  </Typography>
              }
            </>
          )}
          onDragEnd={onDragEnd}
          page='AREA'
          markupList={list}
          markupProps={{
            multiSelect: multiSelect,
            setMultiSelect: setMultiSelect,
            map: map,
          }} />
    </>
  );
};

export default AreaMarkup;

// -*- mode: RJSX; js-indent-level: 2; -*-

import { Select, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Flag from 'react-flagpack';

const languages = [
  { id: 'en', name: 'English', flag: 'GB-UKM' },
  { id: 'fi', name: 'suomi', flag: 'FI' },
  { id: 'sv', name: 'svenska', flag: 'SE' },
  { id: 'no', name: 'norsk', flag: 'NO' },
  { id: 'de', name: 'Deutsch', flag: 'DE' },
  { id: 'fr', name: 'française', flag: 'FR' },
  { id: 'it', Name: 'italiana', flag: 'IT' },
  { id: 'hu', Name: 'Magyar', flag: 'HU' },
  { id: 'sk', name: 'Slovák', flag: 'SK' },
  { id: 'pl', name: 'polski', flag: 'PL' },
];

const LanguageSelect = () => {
  const { t, i18n } = useTranslation();
  return (
    <Select
        disableUnderline
        value={t('lang-id')}
        onChange={lang => i18n.changeLanguage(lang.target.value)}>
      {languages.map((lang) => (
        <MenuItem key={lang.id} value={lang.id}>
          <Flag
              code={lang.flag}
              gradient='real-linear'
              size='m'
              hasDropShadow
              hasBorder
              hasBorderRadius/>
        </MenuItem>
      ))}
    </Select>
  );
};

export default LanguageSelect;

// -*- mode: RJSX; js-indent-level: 2; -*-

import { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

const CSSTransitionWrapper = ({children, ...props}) => {
  const nodeRef = useRef(null);
  return (
    <CSSTransition
        nodeRef={nodeRef}
        {...props}>
      <div ref={nodeRef}>
        {children}
      </div>
    </CSSTransition>
  );
};

export default CSSTransitionWrapper;

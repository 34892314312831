// -*- mode: RJSX; js-indent-level: 2; -*-

import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { logout } from './user';
import { defaultColors } from '../icons/TargetStyles';
import { apiRequest, createApiAsyncThunk } from './api';
import { impersonateUser } from './ui';
import { refreshDesignContents } from './markup';

const lookupDesign = (designs, id) => {
  if (!designs) {
    return null;
  }
  for (let i = 0; i < designs.length; i++) {
    if (designs[i].id === id) {
      return designs[i];
    }
  }
  return null;
};

const lookupDesignIndex = (designs, id) => {
  for (let i = 0; i < designs.length; i++) {
    if (designs[i].id === id) {
      return i;
    }
  }
  return -1;
};

export const useDesigns = () => useSelector((state) => state.designs.list);
export const useWorkingOnDesigns = () => useSelector((state) => state.designs.working);
export const useDesignPreview = (id) => useSelector((state) => {
  const design = lookupDesign(state.designs.list, id);
  if (design) {
    const preview = state.designs.previews[id];
    if (preview && preview.version >= design.version) {
      return preview;
    }
  }
  return undefined;
});

export const useDesign = (id) => useSelector((state) => {
  const design = lookupDesign(state.designs.list, id);
  if (!design) {
    return null;
  }
  const cont = state.markup[id];
  if (!cont) {
    return design;
  }
  return {
    ...design,
    contents: {
      background: cont.background ?? [],
      area: cont.area ?? [],
      point: cont.point ?? [],
      lookup: cont.lookup ?? {},
      error: cont.error ?? false,
    },
  };
});

export const createNewDesign = (name, source) => apiRequest({
  method: 'put',
  url: '/api/v1/design/new',
  input: source ? {name, source} : {name},
  verify: (resp) => resp.data?.status === 'SUCCESS',
  ok: ({status, ...out}) => out,
});

export const refreshDesigns = createApiAsyncThunk('design/list', null, {
  url: '/api/v1/design/list',
  ok: ({designs}) => designs ?? [],
});

export const refreshPreview = createApiAsyncThunk('design/preview', null, {
  url: (id) => `/api/v1/design/${id}/preview/layer`,
});

export const getDesignPreviewLayer = (id) => apiRequest({
  url: `/api/v1/design/${id}/preview/layer`,
});

export const editDesign = createApiAsyncThunk('design/edit', null, {
  method: 'post',
  url: ({tracking, design}) => `/api/v1/design/${design.id}` + (tracking ? `?tracking=${tracking}` : ''),
  input: ({design}) => design,
  verify: (resp) => resp.data?.status === 'SUCCESS',
  ok: ({status, ...out}) => out,
});

export const deleteDesign = createApiAsyncThunk('design/delete', (id, tracking) => ({id, tracking}), {
  method: 'delete',
  url: ({id, tracking}) => `/api/v1/design/${id}` + (tracking ? `?tracking=${tracking}` : ''),
});

export const shareEditStatus = (design) => apiRequest({
  method: 'get',
  url: `/api/v1/design/${design}/editors`,
  ok: ({status, ...out}) => out,
});

export const shareEditDelete = (design, user) => apiRequest({
  method: 'delete',
  url: `/api/v1/design/${design}/editors/${user}`,
});

export const redeemEditorToken = (token) => apiRequest({
  method: 'post',
  url: '/api/v1/editors/redeem',
  input: {token},
  verify: (resp) => resp.data?.status === 'SUCCESS',
  ok: ({status, ...design}) => design,
});

export const updateLatestColors = (design, color, dispatch, tracking) => {
  if (defaultColors.indexOf(color) >= 0) {
    return;
  }
  let latest = design.latestColors ?? [];
  let min = 1e18;
  let minIndex = 0;
  let max = 0;
  let found = -1;
  for (let i = 0; i < latest.length; i++) {
    if (latest[i].color === color) {
      found = i;
    }
    if (latest[i].priority < min) {
      min = latest[i].priority;
      minIndex = i;
    }
    if (latest[i].priority > max) {
      max = latest[i].priority;
    }
  }
  if (found >= 0 && latest[found].priority >= max) {
    // already latest
    return;
  }
  const place = found >= 0 ? found : latest.length < 12 ? latest.length : minIndex;
  dispatch(editDesign({
    design: {
      id: design.id,
      latestColors: [
        ...latest.slice(0, place),
        { color: color, priority: max+1 },
        ...latest.slice(place+1),
      ]
    },
    tracking,
  }));
};

export const trackDesignChanges = () => apiRequest({
  url: `/api/v1/design/tracking`,
  ok: ({url}) => new WebSocket(url),
});

export const designsSlice = createSlice({
  name: 'designs',
  initialState: {
    list: undefined,
    previews: {},
    working: false,
  },
  reducers: {
    newDesign: (state, action) => {
      state.list.push(action.payload);
    },
    designDeleted: (state, action) => {
      state.list = state.list.filter((d) => d.id !== action.payload);
      delete state.previews[action.payload];
    },
    designUpdated: (state, action) => {
      state.list = state.list.map((d) => d.id === action.payload.id ? action.payload : d);
      delete state.previews[action.payload];
    },
    designRedeemed: (state, action) => {
      state.list = [...state.list.filter((d) => d.id !== action.payload.id), action.payload];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(refreshDesigns.pending, (state, action) => ({ ...state, working: true }))
      .addCase(refreshDesigns.fulfilled, (state, action) => {
        state.list = action.payload;
        state.working = false;
      })
      .addCase(refreshDesignContents.fulfilled, (state, action) => {
        if (action.payload?.notFound && state.list) {
          state.list = state.list.filter((d) => d.id !== action.meta.arg.id);
          delete state.previews[action.meta.arg.id];
        }
      })
      .addCase(refreshPreview.pending, (state, action) => {
        const design = lookupDesign(state.list, action.meta.arg);
        if (design) {
          state.previews[action.meta.arg] = {
            version: design.version,
            url: null,
            base: null,
            interesting: null,
          };
        }
      })
      .addCase(refreshPreview.fulfilled, (state, action) => {
        const design = lookupDesign(state.list, action.meta.arg);
        if (design && action.payload?.url) {
          state.previews[action.meta.arg] = {
            version: design.version,
            url: action.payload.url,
            base: action.payload.base,
            interesting: action.payload.interesting,
          };
        }
      })
      .addCase(editDesign.pending, (state, action) => {
        if (action.meta.arg.id) {
          const design = lookupDesign(state.list, action.meta.arg.design.id);
          if (design) {
            design.updating = true;
            if (action.meta.arg.design.name) {
              design.name = action.meta.arg.design.name;
            }
          }
        }
      })
      .addCase(editDesign.fulfilled, (state, action) => {
        if (action.meta.arg.design.id) {
          if (action.payload) {
            const idx = lookupDesignIndex(state.list, action.meta.arg.design.id);
            if (idx >= 0) {
              state.list[idx] = action.payload;
            }
          } else {
            const design = lookupDesign(state.list, action.meta.arg.design.id);
            if (design) {
              design.updating = false;
            }
          }
        }
      })
      .addCase(deleteDesign.pending, (state, action) => {
        state.list = state.list.filter((d) => d.id !== action.meta.arg.id);
        delete state.previews[action.meta.arg.id];
      })
      .addCase(logout, (state, action) => ({ list: undefined, lookup: {}, previews: {}, working: false }))
      .addCase(impersonateUser, (state, action) => ({ list: undefined, lookup: {}, previews: {}, working: false }))
      .addDefaultCase((state, action) => {});
  }
});

export const { newDesign, designDeleted, designUpdated, designRedeemed } = designsSlice.actions;
export default designsSlice.reducer;

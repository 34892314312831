// -*- mode: RJSX; js-indent-level: 2; -*-

import { createContext, cloneElement, useEffect, useContext } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Zoom } from '@material-ui/core';
import { Virtuoso } from 'react-virtuoso';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import clsx from 'clsx';
import FabSpacer from '../common/FabSpacer';
import { SimpleMarkupItemCard } from './MarkupItemCard';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'none',
    whiteSpace: 'nowrap',
  },
  root: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    whiteSpace: 'initial',
    display: 'flex',
    flexDirection: 'column',
    transition: theme.transitions.create('left'),
  },
  content: {
    flexGrow: 1,
    width: '100%',
    overflowY: 'auto',
  },
  cardWrapper: {
    paddingLeft: '1em',
    paddingRight: '1em',
    paddingBottom: '1em',
  },
  bottomPanel: {
    width: '100%',
  },
  fab: {
    position: 'absolute',
    right: '1em',
    bottom: '1em',
  },
}));

// Workaround to Virtuoso/BeautifulDnD incompatibility.
// Ref. https://codesandbox.io/s/react-virutoso-with-react-beautiful-dnd-e6vmq?file=/src/index.js
window.addEventListener("error", (e) => {
  if (e.message === "ResizeObserver loop completed with undelivered notifications." ||
      e.message === "ResizeObserver loop limit exceeded") {
    e.stopImmediatePropagation();
  }
});

const TabPanelContext = createContext({});

const CardWrapper = ({className, provided, children}) => (
  <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      className={clsx(
        provided.draggableProps.className,
        provided.dragHandleProps.className,
        className,
      )}>
    {children}
  </div>
);

const HeightPreservingItem = ({children, ...props}) => (
  <div {...props} style={{height: props['data-known-size'] || undefined}}>
    {children}
  </div>
);

const DrawerTabPanel = ({
  className,
  design,
  current,
  container,
  instructions,
  bottomPanel,
  fab,
  onActivate,
  onDeactivate,
  children,
  page,
  tracking,
  onDragEnd,
  markupList,
  markupProps,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const ctx = useContext(TabPanelContext);

  const fabTransition = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  useEffect(() => {
    if (onActivate && ctx.current === ctx.index) {
      onActivate();
    }
  }, [ctx, onActivate]);

  useEffect(() => {
    if (onDeactivate && ctx.current !== ctx.index) {
      onDeactivate();
    }
  }, [ctx, onDeactivate]);

  if (container) {
    return (
      <div className={classes.container} style={{}}>
        {children.map((ch, i) => (
          <TabPanelContext.Provider key={''+i} value={{index: i, current: current}}>
            {ch}
          </TabPanelContext.Provider>
        ))}
      </div>
    );
  }

  return (
    <>
      <div className={classes.root} style={{left: `${(ctx.index-ctx.current)*100}%`}}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable
              mode='virtual'
              type={page}
              droppableId={`${page}/root`}
              renderClone={(provided, snapshot, rubric) => (
                <CardWrapper className={classes.cardWrapper} provided={provided}>
                  <SimpleMarkupItemCard
                    design={design}
                    markup={markupList[rubric.source.index]}
                    tracking={tracking}
                    {...markupProps} />
                </CardWrapper>
              )}>
            {(provided, snapshot) => (
              <Virtuoso
                  className={classes.content}
                  components={{
                    Item: HeightPreservingItem,
                    Header: () => instructions || null,
                    Footer: fab ? () => <FabSpacer padding='0.5em'/> : undefined,
                  }}
                  scrollerRef={provided.innerRef}
                  data={markupList}
                  itemContent={(index, m) => (
                    <Draggable
                        key={'' + m.properties.id}
                        type={page}
                        draggableId={'' + m.properties.id}
                        index={index}>
                      {(provided, snapshot) => (
                        <CardWrapper className={classes.cardWrapper} provided={provided}>
                          <SimpleMarkupItemCard design={design} markup={m} tracking={tracking} {...markupProps}/>
                        </CardWrapper>
                      )}
                    </Draggable>
                  )}/>
            )}
          </Droppable>
        </DragDropContext>
        {
          bottomPanel &&
            <div className={classes.bottomPanel}>
              {bottomPanel}
            </div>
        }
      </div>
      {
        fab?.length &&
          fab.map((f) => {
            const delay = ctx.current === ctx.index ? (fabTransition.exit + 100*f.position) : 100*f.position;
            return (
              <Zoom in={ctx.current === ctx.index && f.visible}
                    key={f.key}
                    timeout={{enter: fabTransition.enter + delay, exit: fabTransition.exit + delay}}
                    style={{
                      transitionDelay: `${delay}ms`,
                      marginRight: `${f.position*64}px`,
                    }}>
                {cloneElement(f.fab, {className: classes.fab})}
              </Zoom>
            );
          })
      }
    </>
  );
};

export default DrawerTabPanel;

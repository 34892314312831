// -*- mode: RJSX; js-indent-level: 2; -*-

import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { TileLayer } from 'react-leaflet';
import MapView from '../map/MapView';
import { boundsForDesign } from '../util';
import { getDesignPreviewLayer } from '../state/designs';

const useStyles = makeStyles((theme) => ({
  map: {
    width: 'calc(90vw - 50px)',
    height: 'calc(90vh - 150px)',
  },
}));

const Preview = ({design, mapLayers, initialLayers}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [layers, setLayers] = useState(initialLayers);
  const [previewUrl, setPreviewUrl] = useState(null);

  useEffect(() => {
    if (previewUrl === null) {
      setPreviewUrl('');
      getDesignPreviewLayer(design.id, {dispatch: dispatch}).then((u) => setPreviewUrl(u?.url));
    }
  }, [previewUrl, design, dispatch]);

  return (
    <MapView
        layers={layers}
        setActiveBaseMap={(id) => setLayers({...layers, baseMapId: id})}
        setActiveOverlays={({remove = [], add}) => {
          const filtered = layers.overlayIds.filter((i) => remove.indexOf(i) < 0);
          setLayers({
            ...layers,
            overlayIds: add ? [...filtered, add] : filtered,
          });
        }}
        className={classes.map}
        bounds={boundsForDesign(design) ?? [[36, -12], [71, 31]]}>
      {
        previewUrl &&
        <TileLayer
            zIndex={100}
            url={previewUrl}
            minZoom={0}
            maxZoom={20}/>
      }
    </MapView>
  );
};

export default Preview;

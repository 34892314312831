import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { debug } from './util';
import lang_en from './lang/en.json';
import lang_fi from './lang/fi.json';
import lang_sv from './lang/sv.json';
import lang_no from './lang/no.json';
import lang_hu from './lang/hu.json';
import lang_it from './lang/it.json';
import lang_sk from './lang/sk.json';
import lang_fr from './lang/fr.json';
import lang_de from './lang/de.json';
import lang_pl from './lang/pl.json';

i18n.use(LanguageDetector).use(initReactI18next).init({
    resources: {
        en: { translation: lang_en },
        fi: { translation: lang_fi },
        sv: { translation: lang_sv },
        no: { translation: lang_no },
        hu: { translation: lang_hu },
        it: { translation: lang_it },
        sk: { translation: lang_sk },
        fr: { translation: lang_fr },
        de: { translation: lang_de },
        pl: { translation: lang_pl },
    },
    compatibilityJSON: 'v3',
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false,
    },
    debug: debug,
});

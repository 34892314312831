// -*- mode: RJSX; js-indent-level: 2; -*-

import { createContext } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import L from 'leaflet';
import clsx from 'clsx';
import proj4 from 'proj4';
import { dictionary as targetsDictionary } from './TargetIcons';
import { dictionary as stylesDictionary } from './TargetStyleIcons';
import { withoutAlpha, justAlpha } from '../util';
import CrossStripePattern from './CrossStripePattern';
import './markers.css';

export const dictionary = {
  ...targetsDictionary,
  ...stylesDictionary,
};

export const poiIcons = [
  'circleLarge',
  'circleSmall',
  'pin',
  'crosshair',
  'tower',
  'cabin',
  'huntingBlind',
  'camp',
  'shootingRange',
  'parking',
  'car',
  'boat',
  'groundNest',
  'birdNest',
  'feeding',
  'lickingstone',
  'camera',
  'mooseTracks',
  'otherTracks',
  'trap',
  'moose',
  'deer',
  'reindeer',
  'bear',
  'fox',
  'boar',
  'bird',
  'rabbit',
  'fish',
  'berries',
  'mushroom',
  'kill',
  'arrowN',
  'arrowNE',
  'arrowE',
  'arrowSE',
  'arrowS',
  'arrowSW',
  'arrowW',
  'arrowNW',
];

export const lineIcons = [
  'solid',
  'dashed',
  'dotted',
  'dashDot',
];

export const areaIcons = [
  'filled',
  'onlyFill',
  'inverted',
  'onlyInverted',
  'shadingInside',
  'shadingOutside',
  'hollow',
];

export const patternStyles = [
  'uniform',
  'fwDiag',
  'bwDiag',
  'crossDiag',
];

export const stylesForGeometryType = {
  'Point': poiIcons,
  'MultiPoint': poiIcons,
  'LineString': lineIcons,
  'MultiLineString': lineIcons,
  'Polygon': areaIcons,
  'MultiPolygon': areaIcons,
};

export const typeForGeometryType = (markup) => {
  switch (markup.type) {
  case 'Feature':
    return {
      'Point': 'poi',
      'MultiPoint': 'poi',
      'LineString': 'line',
      'MultiLineString': 'line',
      'Polygon': 'area',
      'MultiPolygon': 'area',
    }[markup.geometry?.type];
  case 'FinProperty':
    return 'prop-fi';
  default:
    return null;
  }
};

export const subStylesForStyle = {
  'filled': patternStyles,
  'onlyFill': patternStyles,
  'inverted': patternStyles,
  'onlyInverted': patternStyles,
  'shadingInside': patternStyles,
  'shadingOutside': patternStyles,
  'hollow': null,
};

export const opacitySupportedForStyle = {
  'filled': true,
  'onlyFill': true,
  'inverted': true,
  'onlyInverted': true,
  'shadingInside': true,
  'shadingOutside': true,
};

export const sizeSettingsForMarkup = (m) => {
  if (['Feature', 'OmaRiista', 'FinProperty', 'FinStateHunting'].indexOf(m.type) < 0) {
    return null;
  }
  switch (m.geometry.type) {
  case 'Point':
  case 'MultiPoint':
    return {
      text: 'symbol-size',
      min: 10,
      max: 50,
      step: 5,
      scaling: true,
    };
  case 'LineString':
  case 'MultiLineString':
    return {
      text: 'line-width',
      min: 1,
      max: 50,
    };
  case 'Polygon':
  case 'MultiPolygon':
    const mainStyle = m.properties.style.split('/')[0];
    if (mainStyle === 'onlyFill' || mainStyle === 'onlyInverted') {
      return null;
    }
    return {
      text: 'line-width',
      min: 1,
      max: 50,
    };
  default:
    return null;
  }
};

export const dashForLine = (props, weight) => {
  let dash = null;
  switch (props.style.split('/')[0]) {
  case 'dashed':
    dash = [2, 2];
    break;
  case 'dotted':
    dash = [0, 2];
    break;
  case 'dashDot':
    dash = [2, 2, 0, 2];
    break;
  default:
    break;
  }
  if (dash) {
    dash = dash.map((d) => d * weight).join(' ');
  }
  return dash;
};

//   (1/(2^((z-18.75) * 0.4))) / 2^(18-z)
// = 1/(2^((z-18.75) * 0.4 + 18 - z))
// = 1/(2^(0.4z - 7.5 + 18 - z))
// = 1/(2^(10.5 - 0.6z))
// = 2^(0.6z - 10.5)
export const weightForZ = (z, text) => !text ? Math.pow(2, 0.6*z - 10.5) : Math.pow(2, 0.5*z - 8.9);

export const registerMapPatterns = (map) => ({});
export const MapPatternContext = createContext();

export const polygonOptions = (props, z, map, patterns) => {
  const [mainStyle, subStyle] = (props.style || '').split('/');
  const color = withoutAlpha(props.color);
  const alpha = justAlpha(props.color, 0.5);
  const patkey = `${subStyle}/${color}/${alpha}`;
  let pat = patterns[patkey];
  if (!pat) {
    switch (subStyle) {
    case 'fwDiag':
      pat = new L.StripePattern({
        color: color,
        opacity: alpha,
        patternTransform: 'rotate(-45)',
      });
      break;
    case 'bwDiag':
      pat = new L.StripePattern({
        color: color,
        opacity: alpha,
        patternTransform: 'rotate(45)',
      });
      break;
    case 'crossDiag':
      pat = new CrossStripePattern({
        color: color,
        opacity: alpha,
        patternTransform: 'rotate(45)',
      });
      break;
    default:
      break;
    }
    if (pat) {
      pat.addTo(map);
      patterns[patkey] = pat;
    }
  }
  return {
    stroke: mainStyle !== 'onlyFill' && mainStyle !== 'onlyInverted',
    color: withoutAlpha(props.color),
    weight: (props.size ?? 1) * (z !== null ? weightForZ(z) : 1),
    fill: mainStyle !== 'hollow',
    fillOpacity: pat ? null : justAlpha(props.color, 0.5),
    fillPattern: pat,
  };
};

export const polylineOptions = (props, z) => {
  const weight = (props.size ?? 1) * (z !== null ? weightForZ(z) : 1);
  return ({
    color: withoutAlpha(props.color),
    weight: weight,
    dashArray: dashForLine(props, weight),
  });
};

const arrowAngle = 15 / 180 * Math.PI;
const arrowAngleSin = Math.sin(arrowAngle);
const arrowAngleCos = Math.cos(arrowAngle);

export const arrowizePolylines = (coords, props, z) => {
  if (typeof(coords[0][0]) !== 'number') {
    // MultiLineString
    return coords.flatMap((c) => arrowizePolylines(c, props, z));
  }
  if (coords.length < 2) {
    // Single point (?)
    return [coords];
  }
  const goog = proj4('EPSG:3857');
  const q = goog.forward(coords[coords.length-1]);
  const w = (props.size ?? 1) * 20;
  for (let prev = 2; prev <= coords.length; prev++) {
    const p = goog.forward(coords[coords.length-prev]);
    const qp = [p[0] - q[0], p[1] - q[1]];
    const len = Math.sqrt(qp[0]*qp[0] + qp[1]*qp[1]);
    if (len >= 1) {
      qp[0] *= w / len;
      qp[1] *= w / len;
      return [
        coords,
        [
          goog.inverse([
            q[0] + qp[0] * arrowAngleCos - qp[1] * arrowAngleSin,
            q[1] + qp[0] * arrowAngleSin + qp[1] * arrowAngleCos,
          ]),
          coords[coords.length-1],
          goog.inverse([
            q[0] + qp[0] * arrowAngleCos + qp[1] * arrowAngleSin,
            q[1] - qp[0] * arrowAngleSin + qp[1] * arrowAngleCos,
          ]),
        ],
      ];
    }
  }
  // Too short a line
  return [coords];
};

const labelContext = document.createElement('canvas').getContext('2d');

export const markerIcon = (props, name, selected, interactive, hilit, z) => {
  const [mainStyle, subStyle] = props.style.split('/');
  const sz = props.size * 2 * (subStyle === 'nonscaled' ? 0.5 : weightForZ(z));
  const lsz = props.size * 2 * (subStyle === 'nonscaled' ? 0.5 : weightForZ(z, true));
  if (mainStyle === 'none') {
    // Just label
    if (lsz < 2) {
      return null;
    }
    return new L.DivIcon({
      className: clsx('trapmap-marker-holder', hilit && 'highlighted'),
      html: renderToStaticMarkup(
        <div
            className='trapmap-label'
            style={{
              color: props.color,
              fontSize: lsz*3,
            }}>
          {name ?? ''}
        </div>
      ),
    });
  }
  if (sz < 2) {
    return null;
  }

  let labelDY;
  if (name && lsz > 6) {
    labelContext.font = (lsz/1.5)+'px Roboto';
    const {actualBoundingBoxAscent, fontBoundingBoxAscent} = labelContext.measureText(name);
    labelDY = actualBoundingBoxAscent - fontBoundingBoxAscent;
  }
  return new L.DivIcon({
    className: clsx('trapmap-marker-holder', hilit && 'highlighted'),
    html: renderToStaticMarkup(
      <>
        <div className='trapmap-marker-background'/>
        <div className={clsx('trapmap-marker-icon',
                             selected && 'trapmap-marker-selected',
                             !interactive && 'not-interactive')}
             style={{transform: `scale(${sz/12})`}}>
          <Index id={mainStyle} color={props.color}/>
        </div>
        {
          name && lsz > 6 &&
            <div
                className='trapmap-marker-label'
                style={{
                  fontSize: `${lsz/1.5}px`,
                  transform: `translateX(-50%) translateY(${sz*0.6+labelDY}px)`,
                }}>
              {name}
            </div>
        }
      </>
    ),
  });
};

export const defaultColors = [
  '#ffd200',
  '#be0000',
  '#ff7d00',
  '#82503c',
  '#7de100',
  '#00d2e6',
  '#005ad2',
];

export const ScaledIcon = ({size, children}) => {
  const scale = size/24;
  const txy = (size - 24) / 2;
  return (
    <div style={{width: size, height: size}}>
      <div style={{width: '24px', height: '24px', transform: `translate(${txy}px,${txy}px) scale(${scale})`}}>
        {children}
      </div>
    </div>
  );
};

const Index = ({id, ...props}) => {
  const gen = dictionary[id];
  return !gen ? null : gen(props);
};

export default Index;

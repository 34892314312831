// -*- mode: RJSX; js-indent-level: 2; -*-

import { configureStore } from '@reduxjs/toolkit';
import userReducer from './user';
import designsReducer from './designs';
import markupReducer from './markup';
import layersReducer from './layers';
import uiReducer from './ui';

export default configureStore({
  reducer: {
    user: userReducer,
    designs: designsReducer,
    markup: markupReducer,
    layers: layersReducer,
    ui: uiReducer,
  },
});

// -*- mode: RJSX; js-indent-level: 2; -*-

import { useState, useEffect, useMemo, useRef } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Popover,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
  Button,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TransitionGroup } from 'react-transition-group';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import MenuIcon from '@material-ui/icons/Menu';
import BackIcon from '@material-ui/icons/ArrowBack';
import AccountIcon from '@material-ui/icons/AccountCircle';
import KeyIcon from '@material-ui/icons/VpnKey';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import ComputerIcon from '@material-ui/icons/Computer';
import DeleteIcon from '@material-ui/icons/Delete';
import InfoIcon from '@material-ui/icons/Info';
import FaceIcon from '@material-ui/icons/Face';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import CSSTransitionWrapper from './CSSTransitionWrapper';
import LanguageSelect from './LanguageSelect';
import CombineAccountsDialog from './CombineAccountsDialog';
import { useUser, requestLogout, listMyLogins, deleteLogin, resolveUser } from '../state/user';
import { brand, brandIsTracker, useNavigateUp } from '../util';
import { useTitleSuffix, useImpersonating, impersonateUser } from '../state/ui';
import Logo from '../img/logo.svg';
import licenses from './licenses.json';

const useStyles = makeStyles((theme) => ({
  bar: {
    backgroundColor: 'white',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create('background-color', {
      duration: theme.transitions.duration.shortest,
    })
  },
  title: {
    flexGrow: 1,
    minWidth: 0,
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  popup: {
    paddingLeft: '1em',
    paddingRight: '1em',
  },
  firstLine: {
    paddingTop: '1em',
  },
  lastLine: {
    paddingBottom: '1em',
  },
  logo: {
    width: '30px',
    height: '30px',
    padding: '9px',
    marginLeft: '-12px',
  },
}));

const AppControls = ({visible, menu}) => {
  const classes = useStyles();
  const [userMenuEl, setUserMenuEl] = useState(null);
  const { displayName, uniqueName, admin, brands } = useUser();
  const impersonating = useImpersonating();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const upPath = useNavigateUp();
  const titleSuffix = useTitleSuffix();
  const { t } = useTranslation();
  const [loginsListOpen, setLoginsListOpen] = useState(false);
  const [allLogins, setAllLogins] = useState(null);
  const [aboutOpen, setAboutOpen] = useState(false);
  const [pretendOpen, setPretendOpen] = useState(false);
  const pretendInput = useRef();
  const [combineDialogOpen, setCombineDialogOpen] = useState(false);

  useEffect(() => {
    if (loginsListOpen) {
      setAllLogins(null);
      listMyLogins().then((list) => {
        list = list.map((i) => ({...i, lastSeen: new Date(Date.parse(i.lastSeen))}));
        list.sort((a, b) => {
          if (a.current !== b.current) {
            return a.current ? -1 : 1;
          }
          return b.lastSeen.getTime() - a.lastSeen.getTime();
        });
        setAllLogins(list || []);
      });
    }
  }, [loginsListOpen]);

  const liclist = useMemo(() => {
    const copy = [...licenses];
    copy.sort((a, b) => a.name.localeCompare(b.name));
    return copy;
  }, []);

  return (
    <>
      <TransitionGroup>
        {
          !!visible &&
          <CSSTransitionWrapper timeout={300} classNames='fade'>
            <AppBar
                position='fixed'
                elevation={4}
                className={classes.bar}
                style={impersonating ? {backgroundColor: '#f88'} : {}}>
              <Toolbar>
                {
                  menu ?
                    <IconButton edge='start' color='primary'>
                      <MenuIcon/>
                    </IconButton> :
                    !!upPath ?
                    <IconButton edge='start' color='primary' onClick={() => navigate(upPath, { replace: true })}>
                      <BackIcon/>
                    </IconButton> :
                  <img src={Logo} className={classes.logo} alt='Tracker'/>
                }
                <Typography variant='h6' className={classes.title}>
                  {t('app-title', {brand})}
                  {!!titleSuffix && ' – ' + titleSuffix}
                </Typography>
                <LanguageSelect/>
                <IconButton color='primary' onClick={(ev) => setUserMenuEl(ev.currentTarget)}>
                  <AccountCircle/>
                </IconButton>
              </Toolbar>
            </AppBar>
          </CSSTransitionWrapper>
        }
      </TransitionGroup>
      <Popover
          open={!!userMenuEl}
          anchorEl={userMenuEl}
          anchorOrigin={{
            horizontal: 'left',
            vertical: 'bottom',
          }}
          onClose={() => setUserMenuEl(null)}>
        <List>
          <ListItem>
            <ListItemAvatar>
              <AccountIcon/>
            </ListItemAvatar>
            <ListItemText
                primary={t('signed-in')}
                secondary={displayName && uniqueName ? `${displayName} (${uniqueName})` :
                           displayName || uniqueName || undefined}/>
          </ListItem>
          <ListItem
              button
              onClick={() => {
                setUserMenuEl(null);
                setLoginsListOpen(true);
              }}>
            <ListItemAvatar>
              <KeyIcon/>
            </ListItemAvatar>
            <ListItemText primary={t('all-logins')}/>
          </ListItem>
          {
            admin && impersonating &&
              <ListItem
                  button
                  onClick={() => {
                    setUserMenuEl(null);
                    dispatch(impersonateUser(null));
                  }}>
                <ListItemAvatar>
                  <FaceIcon/>
                </ListItemAvatar>
                <ListItemText primary='Stop pretending'/>
              </ListItem>
          }
          {
            admin && !impersonating &&
              <ListItem
                  button
                  onClick={() => {
                    setUserMenuEl(null);
                    setPretendOpen(true);
                  }}>
                <ListItemAvatar>
                  <FaceIcon/>
                </ListItemAvatar>
                <ListItemText primary='Pretend to be someone'/>
              </ListItem>
          }
          {
            brands?.length === 1 &&
              <ListItem
                  button
                  onClick={() => {
                    setUserMenuEl(null);
                    setCombineDialogOpen(true);
                  }}>
                <ListItemAvatar>
                  <PersonAddIcon/>
                </ListItemAvatar>
                <ListItemText primary={t('combine-login', {brand: ['Tracker', 'Ultracom'].filter((b) => brands[0] !== b)[0]})}/>
              </ListItem>
          }
          <hr/>
          <ListItem
              button
              onClick={() => {
                setUserMenuEl(null);
                setAboutOpen(true);
              }}>
            <ListItemAvatar>
              <InfoIcon/>
            </ListItemAvatar>
            <ListItemText primary={t('about')}/>
          </ListItem>
          <hr/>
          <ListItem
              button
              onClick={() => {
                setUserMenuEl(null);
                dispatch(requestLogout());
              }}>
            <ListItemAvatar>
              <LogoutIcon/>
            </ListItemAvatar>
            <ListItemText primary={t('logout')}/>
          </ListItem>
        </List>
      </Popover>
      <Dialog open={loginsListOpen} onClose={() => setLoginsListOpen(false)}>
        <DialogTitle>
          <Trans>all-logins-title</Trans>
        </DialogTitle>
        <DialogContent>
          {
            !allLogins ? (
              <CircularProgress/>
            ) : !allLogins.length ? (
              <DialogContentText>
                <Trans>all-logins-missing</Trans>
              </DialogContentText>
            ) : (
              <List>
                {
                  allLogins.map((l, i) => (
                    <ListItem key={'' + l.id}>
                      <ListItemAvatar>
                        <ComputerIcon/>
                      </ListItemAvatar>
                      <ListItemText
                          primary={l.userAgent || t('computer')}
                          secondary={
                            l.current ?
                            t('this-login') :
                            l.lastSeen.toLocaleDateString() + ' ' + l.lastSeen.toLocaleTimeString()
                          }/>
                      {
                        !l.current &&
                        <ListItemSecondaryAction>
                          <IconButton
                              edge='end'
                              onClick={() => {
                                deleteLogin(l.id);
                                setAllLogins([
                                  ...allLogins.slice(0, i),
                                  ...allLogins.slice(i+1),
                                ]);
                              }}>
                            <DeleteIcon/>
                          </IconButton>
                        </ListItemSecondaryAction>
                      }
                    </ListItem>
                  ))
                }
              </List>
            )
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setLoginsListOpen(false)}>
            <Trans>close</Trans>
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={aboutOpen} onClose={() => setAboutOpen(false)}>
        <DialogTitle>
          {t('app-title', {brand})}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            &copy; 2021 Tracker Oy
          </DialogContentText>
          <DialogContentText style={{marginBottom: 0}}>
            <Trans>license-info</Trans>
          </DialogContentText>
          <div style={{maxHeight: '25vh', overflowY: 'auto'}}>
            <List style={{padding: 0}}>
              {liclist.map((l, i) => (
                <ListItem
                    dense
                    button={!!l.link}
                    key={''+i}
                    component={l.link ? 'a' : undefined}
                    href={l.link?.replace(/^git\+/, '')}
                    target={l.link ? '_blank' : undefined}
                    rel={l.link ? 'noopener noreferrer' : undefined}>
                  <ListItemText
                      primary={l.name}
                      secondary={l.author}/>
                  <ListItemSecondaryAction>
                    <span style={{fontSize: '75%'}}>
                      {l.licenseType}
                    </span>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAboutOpen(false)}>
            <Trans>close</Trans>
          </Button>
        </DialogActions>
      </Dialog>
      <CombineAccountsDialog
          open={combineDialogOpen}
          onClose={() => setCombineDialogOpen(false)}
          onCompleted={() => window.location.reload(false)}/>
      <Dialog open={pretendOpen} onClose={() => setPretendOpen(false)}>
        <DialogContent>
          <TextField
              autoFocus
              inputRef={pretendInput}
              placeholder='Who?'
              onKeyDown={async (ev) => {
                if (ev.keyCode === 13) {
                  pretendInput.current.blur();
                  const id = await resolveUser(ev.target.value);
                  if (!id) {
                    alert('Not found!');
                  } else {
                    setPretendOpen(false);
                    dispatch(impersonateUser(id));
                  }
                }
              }}/>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AppControls;

// -*- mode: RJSX; js-indent-level: 2; -*-

import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  GridList,
  GridListTile,
  ButtonBase,
  Typography,
} from '@material-ui/core';
import CheckBoxEmpty from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxChecked from '@material-ui/icons/CheckBox';
import TargetIcon, { ScaledIcon } from '../icons/TargetStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '1em',
  },
  border: {
    border: '3px solid transparent',
    transition: theme.transitions.create('border-color', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  selected: {
    border: `3px solid ${theme.palette.primary.main}`,
  },
  none: {
    width: '100%',
    height: '100%',
  },
  noneLabel: {
    paddingLeft: '0.25em',
  },
}));

const StyleChooser = ({value, onChange, styles, color, none}) => {
  const classes = useStyles();
  const cols = styles.length < 6 ? styles.length : 4;
  const w = cols*50 + (cols-1)*8;
  return (
    <div className={classes.root}>
      <GridList cellHeight={50} cols={cols} style={{width: `${w}px`}}>
        {
          !!none &&
          <GridListTile cols={cols}>
            <ButtonBase
                className={clsx(classes.border, classes.none, value === 'none' && classes.selected)}
                onClick={() => onChange && onChange('none')}>
              {value === 'none' ? <CheckBoxChecked/> : <CheckBoxEmpty/>}
              <Typography variant='body1' className={classes.noneLabel}>
                {none}
              </Typography>
            </ButtonBase>
          </GridListTile>
        }
        {styles.map((s) => (
          <GridListTile key={s}>
            <ButtonBase
                className={clsx(classes.border, value === s && classes.selected)}
                onClick={() => onChange && onChange(s)}>
              <ScaledIcon size={44}>
                <TargetIcon id={s} color={color}/>
              </ScaledIcon>
            </ButtonBase>
          </GridListTile>
        ))}
      </GridList>
    </div>
  );
};

export default StyleChooser;

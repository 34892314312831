// -*- mode: RJSX; js-indent-level: 2; -*-

import { useState, useEffect, useRef } from 'react';
import { Container, Paper, Typography, Box, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import AppPage from './common/AppPage';
import LanguageSelect from './common/LanguageSelect';
import { startQRLogin, successfulLogin, authVerify } from './state/user';
import { brand, brandSW, brandIsTracker } from './util';

const useStyles = makeStyles(() => ({
  dialog: {
    padding: '2em',
  },
  content: {
    padding: '2em',
  },
  instructions: {
    marginRight: '2em',
  },
}));

const LoginDialog = () => {
  const classes = useStyles();
  const [qrcode, setQRCode] = useState(null);
  const [failed, setFailed] = useState(false);
  const socket = useRef(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    setFailed(false);
    (async () => {
      socket.current = await startQRLogin();
      if (!socket.current) {
        setFailed(true);
      } else {
        socket.current.onopen = (ev) => {
          socket.current.send('{"request":"QRCODE"}');
        };
        socket.current.onclose = (ev) => {
          setQRCode(null);
          setFailed(true);
        };
        socket.current.onmessage = (ev) => {
          try {
            const json = JSON.parse(ev.data);
            if (json?.qr) {
              setQRCode(json.qr);
            }
            if (json?.token) {
              dispatch(successfulLogin(json));
              dispatch(authVerify());
            }
          } catch(ex) {
            console.error('Error parsing JSON:', ex);
          }
        };
      }
    })();
    return () => {
      if (socket.current) {
        socket.current.onopen = null;
        socket.current.onmessage = null;
        socket.current.onclose = null;
        socket.current.close();
      }
    };
  }, [dispatch]);

  return (
    <AppPage noPadding>
      <Container className={classes.dialog} maxWidth='md'>
        <Paper className={classes.content} elevation={2}>
          <Box display='flex' flexDirection='row'>
            <Box flexGrow={1}>
              <Typography variant='h6' gutterBottom>
                {t('login-title', {brand})}
              </Typography>
            </Box>
            <Box alignSelf='center'>
              <LanguageSelect/>
            </Box>
          </Box>
          {
            failed ?
            <Typography variant='body1'>
              <Trans>login-failed</Trans>
            </Typography> :
            <Box display='flex' flexDirection='row'>
              <Box className={classes.instructions} flexGrow={1}>
                <Typography variant='body1' gutterBottom>
                  {t(brandIsTracker ? 'login-instructions-1-tr' : 'login-instructions-1-uc')}
                </Typography>
                <Typography variant='body1'>
                  {t('login-instructions-3', {brand, sw: brandSW})}
                </Typography>
              </Box>
              <Box alignSelf='center'>
                {
                  qrcode ?
                  <img src={qrcode} alt={'QR'}/> :
                  <CircularProgress/>
                }
              </Box>
            </Box>
          }
        </Paper>
      </Container>
    </AppPage>
  );
};

export default LoginDialog;

// -*- mode: RJSX; js-indent-level: 2; -*-

import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import {
  TextField,
  Box,
  Avatar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Divider,
} from '@material-ui/core';
import clsx from 'clsx';
import { Trans, useTranslation } from 'react-i18next';
import HiddenIcon from '@material-ui/icons/VisibilityOff';
import PersonIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';
import MenuIcon from '@material-ui/icons/MoreVert';
import { useUndo } from './Undo';
import DeleteDesignDialog from '../common/DeleteDesignDialog';
import ShareDesignDialog from '../common/ShareDesignDialog';
import { editDesign } from '../state/designs';
import { requestFocus } from '../state/ui';

const useStyles = makeStyles((theme) => ({
  header: {
    padding: '1em',
    background: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
  },
  headerTitle: {
    minWidth: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  editor: {
    color: theme.palette.primary.contrastText,
  },
  avatar: {
    backgroundColor: theme.palette.primary.dark,
  },
  menuButton: {
    color: theme.palette.primary.contrastText,
  },
}));

const DesignSidebarHeader = ({className, design, tracking, onPreview, onImport, onImportGPX, onShareEdit}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [renaming, setRenaming] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [shareDialog, setShareDialog] = useState(false);
  const [setUndo] = useUndo();

  const owner = design.ownership === 'OWNER';
  return (
    <>
      <Box className={clsx(className, classes.header)}>
        <Box display='flex' flexDirection='row'>
          <Box alignSelf='center'>
            <Avatar className={classes.avatar}>
              {design.hidden ? <HiddenIcon/> : !design.shares.length ? <PersonIcon/> : <GroupIcon/>}
            </Avatar>
          </Box>
          <Box alignSelf='center' flexGrow={1} minWidth={0} paddingLeft='1em' paddingRight='1em'>
            {
              renaming !== null ?
                <TextField
                    autoFocus
                    fullWidth
                    color='secondary'
                    InputProps={{
                      className: classes.editor,
                    }}
                    value={renaming}
                    onChange={(ev) => setRenaming(ev.target.value)}
                    onKeyDown={(ev) => {
                      if (ev.keyCode === 13 && renaming !== design.name) {
                        if (renaming.length) {
                          setUndo(t('undo-design-rename'),
                                  editDesign({design: {id: design.id, name: renaming}, tracking}),
                                  editDesign({design: {id: design.id, name: design.name}, tracking}),
                                  dispatch);
                        }
                        setRenaming(null);
                      }
                    }}
                    onBlur={() => {
                      if (renaming.length && renaming !== design.name) {
                        setUndo(t('undo-design-rename'),
                                editDesign({design: {id: design.id, name: renaming}, tracking}),
                                editDesign({design: {id: design.id, name: design.name}, tracking}),
                                dispatch);
                      }
                      setRenaming(null);
                    }}/> :
              <Typography variant='subtitle1' className={classes.headerTitle}>
                {design.name}
              </Typography>
            }
            <Typography variant='subtitle2'>
              {
                !owner ?
                  t('design-not-owner-short') :
                  design.hidden ?
                  t('design-locked-short') :
                  !design.shares.length ?
                  t('design-private-short') :
                  t('design-shared-short', {count: design.shares.length})
              }
            </Typography>
          </Box>
          <Box alignSelf='center'>
            <IconButton
                className={classes.menuButton}
                onClick={(ev) => {
                  setMenuAnchor(ev.currentTarget);
                  dispatch(requestFocus(null));
                }}>
              <MenuIcon/>
            </IconButton>
          </Box>
          <Menu anchorEl={menuAnchor} open={!!menuAnchor} onClose={() => setMenuAnchor(null)}>
            <MenuItem
                onClick={() => {
                  setMenuAnchor(null);
                  setTimeout(() => {
                    setRenaming(design.name);
                  }, 100);
                }}>
              <Trans>rename</Trans>
            </MenuItem>
            <MenuItem
                onClick={() => {
                  setMenuAnchor(null);
                  onImport();
                }}>
              <Trans>import-th6</Trans>
            </MenuItem>
            <MenuItem
                onClick={() => {
                  setMenuAnchor(null);
                  onImportGPX();
                }}>
              <Trans>import-gpx</Trans>
            </MenuItem>
            <Divider/>
            <MenuItem
                onClick={() => {
                  setMenuAnchor(null);
                  onPreview();
                }}>
              <Trans>preview</Trans>
            </MenuItem>
            {
              owner &&
              <MenuItem
                  onClick={() => {
                    setMenuAnchor(null);
                    setShareDialog(true);
                  }}>
                <Trans>visibility</Trans>
              </MenuItem>
            }
            {
              owner &&
              <MenuItem
                  onClick={() => {
                    setMenuAnchor(null);
                    onShareEdit();
                  }}>
                <Trans>share-edit</Trans>
              </MenuItem>
            }
            <Divider/>
            <MenuItem
                onClick={() => {
                  setMenuAnchor(null);
                  setConfirmDelete(true);
                }}>
              <Trans>delete</Trans>
            </MenuItem>
          </Menu>
        </Box>
      </Box>
      <DeleteDesignDialog
          design={design}
          open={confirmDelete}
          tracking={tracking}
          sharedToMe={!owner}
          onCancel={() => setConfirmDelete(false)}
          onDeleted={() => {}}/>
      <ShareDesignDialog
          design={design}
          open={shareDialog}
          tracking={tracking}
          onClose={() => setShareDialog(false)}/>
    </>
  );
};

export default DesignSidebarHeader;

// -*- mode: RJSX; js-indent-level: 2; -*-

import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { logout } from './user';
import { debug } from '../util';

export const apiHttp = debug ? `http://localhost:8082` : '';
export const tangerHttp = debug ? 'https://tanger-dev.b-bark.com' : 'https://tanger.tracker.fi';

const maybeImpersonate = (url, whom) =>
      !whom ? url : url + (url.indexOf('?') >= 0 ? '&' : '?') + `impersonate=${whom}`;

export const setupInterceptors = (store) => 
  axios.interceptors.request.use((config) => ({
    ...config,
    url: maybeImpersonate(config.url, store.getState().ui?.impersonating),
    headers: {
      ...(config.headers || {}),
      ...(
        config.url.startsWith(tangerHttp) ? {} :
          {'Authorization': `Bearer ${window.localStorage.getItem('authToken')}`}
      ),
    },
    validateStatus: (status) => true,
  }));

export const authToken = () => window.localStorage.getItem('authToken');

export const apiRequest = async ({method = 'get', url, input, verify, ok, fail}) => {
  try {
    const resp = await axios[method](apiHttp + url, input);
    if (resp.status >= 200 && resp.status < 300 && (!verify || verify(resp))) {
      return ok ? ok(resp.data) : resp.data;
    }
    return fail ? fail() : null;
  } catch(e) {
    console.warn('apiRequest failed:', e);
    return fail ? fail() : null;
  }
};

export const verifyResponse = (resp, api) => {
  if (resp.status === 401) {
    api.dispatch(logout());
  }
  return resp.status >= 200 && resp.status < 300;
};

export const createApiAsyncThunk = (id, prepare, {method = 'get', url, input, verify, ok, fail}) => {
  const thunk = createAsyncThunk(id, async (arg, api) => {
    try {
      const data = input ? input(arg) : null;
      const u = typeof(url) === 'function' ? url(arg) : url;
      const resp = await axios[method](apiHttp + u, data);
      if (verifyResponse(resp, api) && (!verify || verify(resp))) {
        return ok ? ok(resp.data, arg) : resp.data;
      }
      return fail ? fail(arg, resp.status) : null;
    } catch(e) {
      return fail ? fail(arg) : null;
    }
  });
  const fn = (...args) => thunk(prepare ? prepare(...args) : args[0]);
  fn.pending = thunk.pending;
  fn.fulfilled = thunk.fulfilled;
  fn.rejected = thunk.rejected;
  return fn;
};

// -*- mode: RJSX; js-indent-level: 2; -*-

import { useEffect, useState, useRef } from 'react';
import { ThemeProvider, CircularProgress, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import {
  createMuiTheme,
  unstable_createMuiStrictModeTheme,
  responsiveFontSizes,
  makeStyles
} from '@material-ui/core/styles';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { TransitionGroup } from 'react-transition-group';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import CSSTransitionWrapper from './common/CSSTransitionWrapper';
import LoginDialog from './LoginDialog';
import LogoutDialog from './LogoutDialog';
import AppControls from './common/AppControls';
import FrontPage from './frontpage/FrontPage';
import MapDesignPage from './designpage/MapDesignPage';
import { HaveMenuContext } from './common/AppPage';
import './App.css';
import { authVerify } from './state/user';
import { debug, usePrevious, brand } from './util';
import { useFeedbackSnack, dismissFeedbackSnack } from './state/ui';

const trackerTheme = responsiveFontSizes((debug ? unstable_createMuiStrictModeTheme : createMuiTheme)({
  palette: {
    primary: {
      main: '#908873',
    },
    secondary: {
      main: '#ad0d12',
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  loginWait: {
    position: 'fixed',
    left: '50%',
    top: '50%',
    margin: '-2.5em 0 0 -2.5em',
    color: 'white',
  },
  feedbackSnack: {
    marginTop: '150px',
  },
  'feedbackSnack-success': {
  },
  'feedbackSnack-error': {
  },
}));

const App = () => {
  const classes = useStyles();
  const haveCredentials = useSelector((state) => !!state.user.authToken);
  const authenticated = useSelector((state) => state.user.authChecked);
  const hadCredentials = usePrevious(haveCredentials);
  const dispatch = useDispatch();
  const location = useLocation();
  const [lastLocation, setLastLocation] = useState(null);
  const [transition, setTransition] = useState('neutral');
  const [realLocation, setRealLocation] = useState(null);
  const [navDelay, setNavDelay] = useState(false);
  const [showMenu, setShowMenu] = useState(0);
  const feedbackSnack = useFeedbackSnack();
  const showMenuSetter = useRef({fn: null});
  const navigate = useNavigate();
  const { t } = useTranslation();
  showMenuSetter.current.fn = (val) => setShowMenu(showMenu + val);

  const loc = location?.pathname || '';
  const lloc = lastLocation?.pathname || '';
  let trans = transition;
  if (loc !== lloc) {
    setLastLocation(location);
    if (loc.startsWith(lloc) && lloc !== '/') {
      trans = 'forward';
    } else if (lloc.startsWith(loc)) {
      trans = 'backward';
    } else {
      trans = 'neutral';
    }
    if (transition !== trans) {
      setTransition(trans);
    }
  }

  const replaceRoute = (delay, path) => {
    if (delay) {
      return;
    }
    setNavDelay(true);
    navigate(path, { replace: true });
    setTimeout(() => setNavDelay(false), 250);
  };

  useEffect(() => {
    if (haveCredentials && !authenticated) {
      dispatch(authVerify());
    } else if (!haveCredentials && hadCredentials) {
      replaceRoute(navDelay, '/logout');
    } else {
      const path = (realLocation ?? location)?.pathname ?? '';
      if (!haveCredentials && path !== '/login' && path !== '/logout') {
        if (path && path !== '/' && path !== '/plan/') {
          replaceRoute(navDelay, '/login?from=' + encodeURIComponent(path));
        } else {
          replaceRoute(navDelay, '/login');
        }
      } else if (haveCredentials && authenticated && path !== '/logout' && !/^\/plan\/[a-zA-Z0-9]*$/.exec(path)) {
        const q = (realLocation ?? location)?.search ?? '';
        if (q.startsWith('?')) {
          const pairs = q.substring(1).split(';');
          for (let i = 0; i < pairs.length; i++) {
            const kv = pairs[i].split('=');
            if (kv.length === 2 && kv[0] === 'from') {
              replaceRoute(navDelay, decodeURIComponent(kv[1]));
              return;
            }
          }
        }
        replaceRoute(navDelay, '/plan/');
      }
    }
  }, [haveCredentials, hadCredentials, authenticated, dispatch, navigate, realLocation, location, navDelay]);

  useEffect(() => {
    if (lastLocation?.pathname !== realLocation?.pathname) {
      setTimeout(() => {
        if (lastLocation?.pathname !== realLocation?.pathname) {
          setRealLocation(lastLocation);
        }
      }, 0);
    }
  }, [lastLocation, realLocation]);

  useEffect(() => {
    window.document.title = t('app-title', {brand});
  }, [t]);

  return (
    <ThemeProvider theme={trackerTheme}>
      <HaveMenuContext.Provider value={showMenuSetter.current}>
        <AppControls visible={haveCredentials && authenticated} menu={showMenu > 0}/>
        <TransitionGroup>
          {
            !realLocation ?
              <CSSTransitionWrapper key='blank' timeout={300} classNames='fade'/> :
            <CSSTransitionWrapper
                key={(realLocation ?? location).key}
                timeout={300}
                classNames={`${trans}-transition`}>
              {!haveCredentials ?
               <Routes location={realLocation ?? location}>
                 <Route path='login' element={<LoginDialog/>} />
                 <Route path='logout' element={<LogoutDialog/>} />
                 <Route path='*' element={<div/>}/>
               </Routes> :
               !authenticated ?
               null :
               <>
                 <Routes location={realLocation ?? location}>
                   <Route path='plan/' element={<FrontPage/>}/>
                   <Route path='plan/:design' element={<MapDesignPage/>} />
                   <Route path='*' element={<div/>}/>
                 </Routes>
               </>
              }
            </CSSTransitionWrapper>
          }
        </TransitionGroup>
        <Snackbar
            anchorOrigin={{horizontal: 'center', vertical: 'top'}}
            className={clsx(classes.feedbackSnack, classes[`feedbackSnack-${feedbackSnack.type}`])}
            open={feedbackSnack.open || false}
            autoHideDuration={5000}
            onClose={() => dispatch(dismissFeedbackSnack())}>
          <Alert variant='filled' severity={feedbackSnack.type || 'info'}>
            {feedbackSnack.message}
          </Alert>
        </Snackbar>
      </HaveMenuContext.Provider>
      {
        haveCredentials && !authenticated &&
          <CircularProgress className={classes.loginWait} size='5em'/>
      }
    </ThemeProvider>
  );
};

export default App;

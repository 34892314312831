// -*- mode: RJSX; js-indent-level: 2; -*-

import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  ButtonBase,
  Collapse,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Checkbox,
  Divider,
} from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import FolderIcon from '@material-ui/icons/FolderOpen';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useUndo } from './Undo';
import TargetIcon, { typeForGeometryType } from '../icons/TargetStyles';
import { fetchUserMarkers, importUserMarkers } from '../state/markup';
import { newMarkup, createNewMarkup, nextZ, deleteMarkup, resurrectMarkup } from '../state/markup';
import { showFeedbackSnack, useMarkupDefaults } from '../state/ui';

const useStyles = makeStyles((theme) => ({
  scaledIcon: {
    transform: 'scale(1.5)',
  },
  group: {
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'start',
    width: 'calc(100% + 48px)',
    backgroundColor: '#d8d8d8',
    marginLeft: '-24px',
    marginRight: '-24px',
  },
  groupTitle: {
    flexGrow: 1,
    alignSelf: 'center',
    paddingLeft: '24px',
  },
  expand: {
    padding: '12px',
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    color: `${theme.palette.action.active} !important`,
  },
  expanded: {
    transform: 'rotate(180deg)',
  },
  dropArea: {
    minWidth: '30em',
    minHeight: '8em',
    border: '3px dashed #6e9712',
    display: 'flex',
    cursor: 'pointer',
  },
  'dropDisabled': {
    border: '3px dashed #979797',
    backgroundColor: 'rgba(143,143,143,0.3)',
    cursor: 'wait',
  },
  'droppable': {
    backgroundColor: 'rgba(110,151,18,0.3)',
  },
  dropAreaText: {
    margin: 'auto',
  },
}));

const ImportItem = ({level, disabled, marker, selected, setSelected}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  let someChecked = false;
  let allChecked = true;
  if (!marker.children) {
    someChecked = selected[marker.id] || false;
    allChecked = someChecked;
  } else {
    marker.children.forEach((m) => {
      if (selected[m.id]) {
        someChecked = true;
      } else {
        allChecked = false;
      }
    });
  }

  const toggleClick = () => {
    if (marker.children) {
      if (allChecked) {
        const none = {...selected};
        marker.children.forEach((m) => delete none[m.id]);
        setSelected(none);
      } else {
        const all = {...selected};
        marker.children.forEach((m) => all[m.id] = true);
        setSelected(all);
      }
    } else {
      setSelected({...selected, [marker.id]: !selected[marker.id]});
    }
  };

  return (
    <>
      {marker.isFolder && <Divider/>}
      <ListItem
          button
          disabled={disabled || false}
          onClick={toggleClick}
          style={{
            paddingLeft: `${level*56}px`,
          }}>
        <ListItemAvatar>
          {
            marker.isFolder ? (
              <FolderIcon/>
            ) : (
              <TargetIcon
                  className={classes.scaledIcon}
                  id={marker.style}
                  color={marker.color} />
            )
          }
        </ListItemAvatar>
        <ListItemText
            primary={marker.name ?? `(${t('no-name')})`}
            secondary={marker.subtitle} />
        <ListItemSecondaryAction>
          <Checkbox
              edge='end'
              checked={allChecked}
              indeterminate={!allChecked && someChecked}
              disabled={disabled || false}
              onChange={toggleClick} />
        </ListItemSecondaryAction>
      </ListItem>
      {
        marker.isFolder &&
        marker.children.map((m) => (
          <ImportItem
              key={m.id}
              level={level+1}
              disabled={disabled}
              marker={m}
              selected={selected}
              setSelected={setSelected} />
        ))
      }
    </>
  );
};

const organize = (markers) => {
  // First, sort correctly
  markers = [...markers];
  markers.sort((a, b) => a.name.toLocaleUpperCase().localeCompare(b.name.toLocaleUpperCase()));
  // Then, sort markers into their respective folders
  const fmap = {};
  markers.forEach((m) => {
    if (m.isFolder) {
      m.children = [];
      fmap[m.id] = m;
    };
  });
  const root = [];
  const froot = [];
  markers.forEach((m) => {
    const f = fmap[m.folder];
    if (f) {
      f.children.push(m);
    } else if (m.isFolder) {
      froot.push(m);
    } else {
      root.push(m);
    }
  });
  // Filter out empty folders
  while(true) {
    let changes = false;
    markers.forEach((m) => {
      if (m.isFolder && !m.children.length) {
        const f = fmap[m.folder];
        if (f) {
          const i = f.children.indexOf(m);
          if (i >= 0) {
            f.children.splice(i, 1);
            changes = true;
          }
        } else {
          const i = froot.indexOf(m);
          if (i >= 0) {
            froot.splice(i, 1);
            changes = true;
          }
        }
      }
    });
    if (!changes) {
      break;
    }
  }
  return [...root, ...froot];
};

const ImportDialog = ({open, source, onClose, map, design, tracking, ...props}) => {
  const classes = useStyles();
  const [phoneMarkers, setPhoneMarkers] = useState(null);
  const [gpxMarkers, setGpxMarkers] = useState(null);
  const [lastMarkerUpdate, setLastMarkerUpdate] = useState(0);
  const [selected, setSelected] = useState({});
  const [chords, setChords] = useState({});
  const [importing, setImporting] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const poiDefaults = useMarkupDefaults('poi');
  const lineDefaults = useMarkupDefaults('line');
  const areaDefaults = useMarkupDefaults('area');
  const [setUndo] = useUndo();
  const [dropCounter, setDropCounter] = useState(0);
  const pPrefix = 'ed315185-d53c-47bb-a838-adb45b0ff56c-';
  const markers = source === 'phone' ? phoneMarkers : source === 'gpx' ? gpxMarkers : null;

  useEffect(() => {
    const now = Date.now();
    if (open && source === 'phone' && now - lastMarkerUpdate > 20000) {
      setPhoneMarkers(null);
      setLastMarkerUpdate(now);
      (async () => {
        const m = await fetchUserMarkers();
        setLastMarkerUpdate(Date.now());
        const markers = [];
        if (m?.personal?.length) {
          const byProvider = {};
          m.personal.forEach((p) => {
            byProvider[p.provider || ''] = [
              ...(byProvider[p.provider || ''] || []),
              p,
            ];
          });
          for (const p in byProvider) {
            markers.push({
              id: pPrefix + p,
              title: t('import-personal') + (!p ? '' : ` (${p})`),
              markers: organize(byProvider[p]),
            });
          }
        }
        m?.groups?.forEach((gm) => {
          if (gm.markers?.length) {
            markers.push({
              id: gm.id,
              title: gm.name,
              markers: organize(gm.markers),
            });
          }
        });
        markers.sort((a, b) => {
          const apri = a.id.startsWith(pPrefix);
          const bpri = b.id.startsWith(pPrefix);
          return apri !== bpri ? (apri ? -1 : 1) :
            a.title.toLocaleUpperCase().localeCompare(b.title.toLocaleUpperCase());
        });
        const lookup = {};
        const setLookup = (g, m) => {
          lookup[m.id] = [...(lookup[m.id] || []), g.id];
          m.children?.forEach((c) => setLookup(g, c));
        };
        markers.forEach((g) => g.markers.forEach((m) => setLookup(g, m)));
        setPhoneMarkers({
          list: markers,
          lookup: lookup,
        });
      })();
    }
  }, [open, source, lastMarkerUpdate, t]);

  useEffect(() => {
    if (open) {
      setSelected({});
      setChords({});
      setGpxMarkers(null);
      setDropCounter(0);
    }
  }, [open]);

  let anySelected = false;
  for (const k in selected) {
    if (selected[k]) {
      anySelected = true;
      break;
    }
  }

  useEffect(() => {
    if (markers?.list?.length && open) {
      const timer = setTimeout(() => {
        setChords((c) => ({
          ...c,
          ...markers.list.reduce((map, f) => {
            if (f.id.startsWith(pPrefix)) {
              map[f.id] = true;
            }
            return map;
          }, {}),
        }));
      }, 500);
      return () => clearTimeout(timer);
    }
    return null;
  }, [markers, open]);

  const ignore = (e) => {
    e.preventDefault();
    e.stopPropagation(); 
  };

  const collectGpxMarkers = (doc, title) => {
    window.FOO = doc;
    if (doc.childNodes.length !== 1 || doc.childNodes[0].nodeName !== 'gpx') {
      // Not a GPX file
      return null;
    }
    const wpts = [];
    const rtes = [];
    const trks = [];
    const nodeNameChild = (node) => {
      let name = undefined;
      node.childNodes.forEach((n) => {
        if (n.nodeName === 'name') {
          name = n.textContent;
        }
      });
      return name;
    };
    const pushPoint = (node, list) => {
      const name = nodeNameChild(node);
      const lat = parseFloat(node.attributes['lat']?.value);
      const lon = parseFloat(node.attributes['lon']?.value);
      if (lat && lon) {
        list.push({name, lat, lon});
      }
    };
    doc.childNodes[0].childNodes.forEach((node) => {
      switch (node.nodeName) {
      case 'wpt': {
        // Waypoint
        pushPoint(node, wpts);
        break;
      }
      case 'rte': {
        // Route
        const name = nodeNameChild(node);
        const rte = [];
        node.childNodes.forEach((node) => {
          if (node.nodeName === 'rtept') {
            pushPoint(node, rte);
          }
        });
        if (rte.length) {
          rtes.push({ name, rte });
        }
        break;
      }
      case 'trk': {
        // Track
        const name = nodeNameChild(node);
        const trk = [];
        node.childNodes.forEach((node) => {
          if (node.nodeName === 'trkseg') {
            const seg = [];
            node.childNodes.forEach((node) => {
              if (node.nodeName === 'trkpt') {
                pushPoint(node, seg);
              }
            });
            trk.push(seg);
          }
        });
        if (trk.length) {
          trks.push({ name, trk });
        }
        break;
      }
      default:
        // Ignore
        break;
      }
    });

    if (wpts.length + rtes.length + trks.length === 0) {
      return null;
    }

    let id = 1;
    const markers = [];
    const sel = {};
    wpts.forEach((wpt) => {
      const myid = '' + (id++);
      markers.push({
        ...poiDefaults,
        isFolder: false,
        id: myid,
        name: wpt.name,
        subtitle: t('point-single'),
        geometry: {
          type: 'Point',
          coordinates: [wpt.lon, wpt.lat],
        },
      });
      if (wpts.length < 10) {
        sel[myid] = true;
      }
    });
    if (wpts.length > 1) {
      const myid = '' + (id++);
      let names = { enabled: false };
      if (wpts.some((w) => w.name)) {
        names = {
          enabled: true,
          names: wpts.map((w) => w.name ?? ''),
        };
      }
      markers.unshift({
        ...poiDefaults,
        isFolder: false,
        id: myid,
        name: t('import-wpt-multi'),
        subtitle: t('point-multi', { count: wpts.length }),
        ignoreName: true,
        geometry: {
          type: 'MultiPoint',
          coordinates: wpts.map((w) => [w.lon, w.lat]),
        },
        names,
      });
      if (wpts.length >= 10) {
        sel[myid] = true;
      }
    }

    rtes.forEach((rte) => {
      const myid = '' + (id++);
      const pts = rte.rte.map((pt) => [pt.lon, pt.lat]);
      let names = { enabled: false };
      if (rte.rte.some((pt) => pt.name)) {
        names = {
          enabled: true,
          names: rte.rte.map((pt) => pt.name ?? ''),
        };
      }
      markers.push({
        ...poiDefaults,
        isFolder: false,
        id: myid,
        name: rte.name,
        subtitle: t('gpx-route-as-points'),
        geometry: {
          type: 'MultiPoint',
          coordinates: pts,
        },
        names,
      });
      sel[myid] = true;

      if (rte.rte.length >= 2) {
        const myid = '' + (id++);
        markers.push({
          ...lineDefaults,
          isFolder: false,
          id: myid,
          name: rte.name,
          subtitle: t('gpx-route-as-line'),
          geometry: {
            type: 'LineString',
            coordinates: pts,
          },
        });
      }

      const last = rte.rte.length-1;
      const closed = rte.rte[0].lat === rte.rte[last].lat && rte.rte[0].lon === rte.rte[last].lon;
      if (rte.rte.length >= 3 || (rte.rte.length >= 2 && !closed)) {
        const ring = closed ? pts : [...pts, pts[0]];
        const myid = '' + (id++);
        markers.push({
          ...areaDefaults,
          isFolder: false,
          id: myid,
          name: rte.name,
          subtitle: t('gpx-route-as-area'),
          geometry: {
            type: 'Polygon',
            coordinates: [ring],
          },
        });
      }
    });

    trks.forEach((trk) => {
      const segs = trk.trk.map((seg) => seg.map((pt) => [pt.lon, pt.lat])).filter((seg) => seg.length >= 2);

      if (segs.length < 1) {
        return;
      }

      const myid = '' + (id++);
      markers.push({
        ...lineDefaults,
        isFolder: false,
        id: myid,
        name: trk.name,
        subtitle: t('gpx-track-as-line'),
        geometry: {
          type: 'MultiLineString',
          coordinates: segs,
        },
      });
      sel[myid] = true;

      const rings = segs.map((seg) => {
        const last = seg.length-1;
        const closed = seg[0][0] === seg[last][0] && seg[0][1] === seg[last][1];
        return closed ? seg : [...seg, seg[0]];
      }).filter((ring) => ring.length >= 3);

      if (rings.length >= 1) {
        const myid = '' + (id++);
        markers.push({
          ...areaDefaults,
          isFolder: false,
          id: myid,
          name: trk.name,
          subtitle: t('gpx-track-as-area'),
          geometry: {
            type: 'Polygon',
            coordinates: rings,
          },
        });
      }
    });

    const lookup = {};
    markers.forEach((m) => lookup[m.id] = ['gpx']);
    setGpxMarkers({
      list: [
        {
          id: 'gpx',
          title,
          markers,
        },
      ],
      lookup
    });
    setChords({ gpx: true });
    setSelected(sel);
    return true;
  };

  const handleGpx = (files) => {
    setDropCounter(0);
    if (files.length > 0) {
      setImporting(true);
      const title = files[0].name;
      const reader = new FileReader();
      reader.onload = (e) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(e.target.result, 'application/xml');
        const err = doc.querySelector('parsererror');
        if (err) {
          dispatch(showFeedbackSnack('error', t('import-error')));
        } else {
          if (!collectGpxMarkers(doc, title)) {
            dispatch(showFeedbackSnack('error', t('import-error')));
          }
        }
        setImporting(false);
      };
      reader.onerror = () => {
        dispatch(showFeedbackSnack('error', t('import-error')));
        setImporting(false);
      };
      reader.readAsText(files[0]);
    }
    document.getElementById('gpx-browse').value = null;
  };

  const importPhoneMarkers = async () => {
    const list = [];
    for (const k in selected) {
      if (selected[k]) {
        const g = markers.lookup[k][0];
        list.push({
          id: k,
          group: g.startsWith(pPrefix) ? null : g,
        });
      }
    }
    setImporting(true);
    if (list.length) {
      const res = await importUserMarkers(list, tracking);
      if (res?.length) {
        res.sort((a, b) => a.name.toLocaleUpperCase().localeCompare(b.name.toLocaleUpperCase()));
        // Combine similar point markers
        for (let i = 1; i < res.length;) {
          let combined = false;
          const prefix = (str) => {
            const m = /^[^ 0-9]+/.exec(str);
            return m?.length ? m[0] : '';
          };
          if (res[i].geometry.type === 'Point') {
            for (let j = 0; j < i; j++) {
              if ((res[j].geometry.type === 'Point' || res[j].geometry.type === 'MultiPoint') &&
                  res[j].style === res[i].style && res[j].color === res[i].color &&
                  prefix(res[j].name) === prefix(res[i].name)) {
                if (res[j].geometry.type === 'Point') {
                  res[j].geometry = {
                    type: 'MultiPoint',
                    coordinates: [res[j].geometry.coordinates],
                  };
                  res[j].names = [res[j].name];
                }
                res[j].geometry.coordinates.push(res[i].geometry.coordinates);
                res[j].names.push(res[i].name);
                combined = true;
                break;
              }
            }
          }
          if (combined) {
            res.splice(i, 1);
          } else {
            i++;
          }
        }
        const bbox = [[90, 180], [-90, -180]];
        const dc = {
          point: design.contents.point,
          area: design.contents.area,
        };
        const fwd = [];
        const bwd = [];
        for (let i = 0; i < res.length; i++) {
          let mdata = {
            type: 'Feature',
            geometry: res[i].geometry,
            properties: {
              style: res[i].style,
              color: res[i].color,
              name: res[i].name,
            },
          };
          if (res[i].description) {
            mdata.properties.description = res[i].description;
          }
          if (res[i].names) {
            const stem = /^(.*?) ?\d*$/.exec(res[i].names[0])[1];
            let sequential = true;
            for (let j = 0; j < res[i].names.length; j++) {
              const split = /^(.*?) ?(\d*)$/.exec(res[i].names[j]);
              if (split[1] !== stem || split[2] !== ''+(j+1)) {
                sequential = false;
                break;
              }
            }
            if (sequential) {
              mdata.properties.name = stem;
            } else {
              mdata.properties.names = {
                enabled: true,
                names: res[i].names,
              };
            }
          }
          const gtype = typeForGeometryType(mdata);
          switch (gtype) {
          case 'poi':
            mdata.properties = {
              ...poiDefaults,
              ...mdata.properties,
              folder: null,
              z: nextZ(dc.point, 'point', false),
              burn: true,
            };
            break;
          case 'line':
            mdata.properties = {
              ...lineDefaults,
              ...mdata.properties,
              folder: null,
              z: nextZ(dc.area, 'area', false),
              burn: true,
            };
            break;
          case 'area':
            mdata.properties = {
              ...areaDefaults,
              ...mdata.properties,
              folder: null,
              z: nextZ(dc.area, 'area', false),
              burn: true,
            };
            break;
          default:
            mdata = null;
            break;
          }
          if (!mdata) {
            continue;
          }
          const m = await createNewMarkup(design.id, mdata, tracking);
          if (m) {
            const am = {design: design.id, ...m};
            dispatch(newMarkup(am));
            fwd.push(resurrectMarkup(am, tracking));
            bwd.push(deleteMarkup(am, tracking));
            if (m.bbox[0] < bbox[0][1]) bbox[0][1] = m.bbox[0];
            if (m.bbox[1] < bbox[0][0]) bbox[0][0] = m.bbox[1];
            if (m.bbox[2] > bbox[1][1]) bbox[1][1] = m.bbox[2];
            if (m.bbox[3] > bbox[1][0]) bbox[1][0] = m.bbox[3];
            switch (gtype) {
            case 'poi':
              dc.point = [...dc.point, m];
              break;
            case 'line':
            case 'area':
              dc.area = [...dc.area, m];
              break;
            default:
              break;
            }
          }
        }
        if (fwd.length > 0) {
          setUndo(t('undo-import'), fwd, bwd);
        }
        if (bbox[0][0] <= bbox[1][0]) {
          map.flyToBounds(bbox);
        } else {
          dispatch(showFeedbackSnack('error', t('import-error')));
        }
      } else {
        dispatch(showFeedbackSnack('error', t('import-error')));
      }
    }
    onClose();
    setImporting(false);
  };

  const importGpxMarkers = async () => {
    setImporting(true);

    const bbox = [[90, 180], [-90, -180]];
    const dc = {
      point: design.contents.point,
      area: design.contents.area,
    };
    const fwd = [];
    const bwd = [];
    const gpx = gpxMarkers.list[0].markers;
    for (let i = 0; i < gpx.length; i++) {
      const { geometry, id, ignoreName, name, subtitle, ...properties } = gpx[i];
      if (!selected[id]) {
        continue;
      }
      if (!ignoreName) {
        properties.name = name;
      }
      let mdata = {
        type: 'Feature',
        geometry,
        properties,
      };
      const gtype = typeForGeometryType(mdata);
      switch (gtype) {
      case 'poi':
        mdata.properties = {
          ...poiDefaults,
          ...mdata.properties,
          folder: null,
          z: nextZ(dc.point, 'point', false),
          burn: true,
        };
        break;
      case 'line':
        mdata.properties = {
          ...lineDefaults,
          ...mdata.properties,
          folder: null,
          z: nextZ(dc.area, 'area', false),
          burn: true,
        };
        break;
      case 'area':
        mdata.properties = {
          ...areaDefaults,
          ...mdata.properties,
          folder: null,
          z: nextZ(dc.area, 'area', false),
          burn: true,
        };
        break;
      default:
        mdata = null;
        break;
      }
      if (!mdata) {
        continue;
      }
      const m = await createNewMarkup(design.id, mdata, tracking);
      if (m) {
        const am = {design: design.id, ...m};
        dispatch(newMarkup(am));
        fwd.push(resurrectMarkup(am, tracking));
        bwd.push(deleteMarkup(am, tracking));
        if (m.bbox[0] < bbox[0][1]) bbox[0][1] = m.bbox[0];
        if (m.bbox[1] < bbox[0][0]) bbox[0][0] = m.bbox[1];
        if (m.bbox[2] > bbox[1][1]) bbox[1][1] = m.bbox[2];
        if (m.bbox[3] > bbox[1][0]) bbox[1][0] = m.bbox[3];
        switch (gtype) {
        case 'poi':
          dc.point = [...dc.point, m];
          break;
        case 'line':
        case 'area':
          dc.area = [...dc.area, m];
          break;
        default:
          break;
        }
      }
    }
    if (fwd.length > 0) {
      setUndo(t('undo-import'), fwd, bwd);
    }
    if (bbox[0][0] <= bbox[1][0]) {
      map.flyToBounds(bbox);
    } else {
      dispatch(showFeedbackSnack('error', t('import-error')));
    }
    
    onClose();
    setImporting(false);
  };

  return (
    <Dialog maxWidth='md' open={open} onClose={() => {
      if (!importing) {
        onClose();
      }
    }}>
      <DialogTitle>
        <Trans>import-title</Trans>
      </DialogTitle>
      <DialogContent>
        {
          source === 'gpx' && !markers?.list ? (
            <>
              <DialogContent>
                <div
                    className={`${classes.dropArea} ${importing ? classes.dropDisabled : dropCounter>0 ? classes.droppable : ''}`}
                    onDragEnter={(e) => { ignore(e); setDropCounter(dropCounter+1); }}
                    onDragOver={(e) => ignore(e) }
                    onDragLeave={(e) => { ignore(e); setDropCounter(dropCounter-1); }}
                    onDrop={importing ? null : (e) => { ignore(e); handleGpx(e.dataTransfer.files); }}
                    onClick={importing ? null : (e) => { ignore(e); document.getElementById('gpx-browse').click(); } }>
                  <Typography variant='body1' className={classes.dropAreaText}>
                    <Trans>
                      import-drop
                    </Trans>
                  </Typography>
                </div>
                <input
                    id='gpx-browse'
                    type='file'
                    accept='.xml,.gpx'
                    style={{display: 'none'}}
                    onChange={(e) => handleGpx(e.currentTarget.files)}/>
              </DialogContent>
            </>
          ) :
          !markers?.list ? (
            <>
              <DialogContentText>
                <Trans>please-wait</Trans>
              </DialogContentText>
              <CircularProgress/>
            </>
          ) : !markers.list.length ? (
            <DialogContentText>
              <Trans>import-nothing</Trans>
            </DialogContentText>
          ) : (
            <>
              <DialogContentText>
                <Trans>import-instructions</Trans>
              </DialogContentText>
              {
                markers.list.map((g) => (
                  <div key={''+g.id}>
                    <ButtonBase
                        className={classes.group}
                        onClick={() => setChords({...chords, [g.id ?? '']: !chords[g.id ?? '']})}>
                      <div className={classes.groupTitle}>
                        <Typography variant='body1'>
                          {g.title}
                        </Typography>
                      </div>
                      <div className={clsx(classes.expand, {[classes.expanded]: chords[g.id ?? '']})}>
                        <ExpandMoreIcon/>
                      </div>
                    </ButtonBase>
                    <Collapse in={chords[g.id ?? ''] || false} timeout='auto'>
                      <List>
                        <ImportItem
                            level={0}
                            disabled={importing}
                            marker={{
                              name: t('import-all'),
                              children: g.markers.flatMap((m) => m.isFolder ? m.children: [m]),
                            }}
                            selected={selected}
                            setSelected={setSelected} />
                        {
                          g.markers.map((m) => (
                            <ImportItem
                                key={m.id}
                                level={0}
                                disabled={importing}
                                marker={m}
                                selected={selected}
                                setSelected={setSelected} />
                          ))
                        }
                      </List>
                    </Collapse>
                  </div>
                ))
              }
            </>
          )
        }
      </DialogContent>
      <DialogActions>
        {
          importing &&
          <>
            <CircularProgress size={32}/>
            <span style={{flexGrow: 1}}/>
          </>
        }
        <Button
            disabled={importing}
            onClick={() => onClose()}>
          <Trans>cancel</Trans>
        </Button>
        <Button
            disabled={importing || !anySelected}
            onClick={() => {
              if (source === 'phone') {
                importPhoneMarkers();
              } else if (source === 'gpx') {
                importGpxMarkers();
              }
            }}>
          <Trans>import</Trans>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImportDialog;

// -*- mode: RJSX; js-indent-level: 2; -*-

import L from 'leaflet';
import 'leaflet.pattern';

const CrossStripePattern = L.Pattern.extend({

  options: {
    weight: 4,
    color: '#000000',
    opacity: 1.0,
  },

  _addShapes: function () {
    this._path = new L.PatternPath({
      stroke: false,
      fill: true,
      fillColor: this.options.color,
      fillOpacity: this.options.opacity,
    });
    this.addShape(this._path);
    this._update();
  },

  _update: function () {
    const w = this.options.width - this._path.options.weight;
    const f = this._path.options.weight;
    this._path.options.d = `M0 ${w} h${w} v-${w} h${f} v${this.options.width} h-${this.options.width} z`;
    //this._path.options.d = `M0 4 H8 V4 H-8 z`;
    //this._path.options.d = 'M0 0 H 8 V 4 H -4 V 4 H -4 z';
    //this._path.options.d = 'M0 0 H 8 V 8 z';
  },

  setStyle: L.Pattern.prototype.setStyle
});

export default CrossStripePattern;

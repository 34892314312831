// -*- mode: RJSX; js-indent-level: 2; -*-

import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

export const debug = process.env.NODE_ENV !== 'production';
export const brand = window.location.host.indexOf('.ultracom.') > 0 ? 'Ultracom' : 'Tracker';
export const brandIsTracker = brand === 'Tracker';
export const brandIsUltracom = brand === 'Ultracom';
export const brandSW = brandIsTracker ? 'Tracker' : 'Ultracom NEXT';

export const earthRadius = 6378137;

export const formatTimestring = (str, opts) => {
  const parsed = Date.parse(str);
  if (parsed && !isNaN(parsed)) {
    return new Date(parsed).toLocaleString([], opts);
  }
  return str;
};

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useNavigateUp = () => {
  const pathParts = (useLocation()?.pathname || '').split('/');
  const nParts = pathParts.length;
  if (nParts > 3 || (nParts === 3 && pathParts[2].length > 0)) {
    return [...pathParts.slice(0, nParts-(pathParts[nParts-1] ? 1 : 2)), ''].join('/');
  }
  return null;
};

export const withoutAlpha = (color) => {
  if (color[0] !== '#' || color.length < 9) {
    return color;
  }
  const argb = parseInt(color.slice(1), 16);
  return '#' + ('000000' + (argb & 0xffffff).toString(16)).slice(-6);
};

export const justAlpha = (color, defaultAlpha = 0.5, hex) => {
  if (color[0] !== '#' || color.length < 9) {
    return hex ? ('0'+Math.round(defaultAlpha * 255).toString(16)).slice(-2) : defaultAlpha;
  }
  const a = color.slice(1, 3);
  if (hex) {
    return a;
  }
  return parseInt(a, 16) / 255;
};

export const colorAsRGBA = (color, defaultAlpha = 0.5) => {
  if (color[0] !== '#' || color.length < 7) {
    return color;
  }
  const argb = parseInt(color.slice(1), 16);
  const b = argb & 0xff;
  const g = (argb >> 8) & 0xff;
  const r = (argb >> 16) & 0xff;
  const a = color.length > 7 ? ((argb >> 24) & 0xff) / 255 : defaultAlpha;
  return `rgba(${r},${g},${b},${a})`;
};

const rgbaRegex = /^rgba\( *(\d+) *, *(\d+) *, *(\d+) *, *([0-9.]*) *\)$/;

export const parseRGBA = (color) => {
  if (color[0] !== '#') {
    const m = rgbaRegex.exec(color);
    if (!m) {
      return null;
    }
    const a = parseFloat(m[4]);
    if (isNaN(a)) {
      return null;
    }
    return [
      parseInt(m[1]),
      parseInt(m[2]),
      parseInt(m[3]),
      Math.round(a * 255),
    ];
  }
  const num = parseInt(color.slice(1), 16);
  switch (color.length) {
  case 4:
    // #rgb
    return [
      ((num >> 8) & 0xf) * 0x11,
      ((num >> 4) & 0xf) * 0x11,
      (num & 0xf) * 0x11,
      0xff,
    ];
  case 7:
    // #rrggbb
    return [
      (num >> 16) & 0xff,
      (num >> 8) & 0xff,
      num & 0xff,
      0xff,
    ];
  case 9:
    // #aarrggbb
    return [
      (num >> 16) & 0xff,
      (num >> 8) & 0xff,
      num & 0xff,
      (num >> 24) & 0xff,
    ];
  default:
    return null;
  }
};

export const textForBackground = (color, background = '#ffffff') => {
  color = parseRGBA(color);
  background = parseRGBA(background);
  if (color) {
    if (color[3] < 255 && background) {
      // Blend
      const a = color[3] / 255;
      const ia = 1 - a;
      color = [
        Math.round(color[0] * a + background[0] * ia),
        Math.round(color[1] * a + background[1] * ia),
        Math.round(color[2] * a + background[2] * ia),
      ];
    }
    const l = 0.299*color[0] + 0.587*color[1] + 0.114*color[2];
    if (l > 200) {
      return '#212121';
    }
  }
  return '#ffffff';
};

export const boundsForDesign = (d) => {
  let min = [90, 180];
  let max = [-90, -180];
  const r = (m) => {
    if (m.bbox && m.properties.visible) {
      min[0] = Math.min(min[0], m.bbox[1]);
      min[1] = Math.min(min[1], m.bbox[0]);
      max[0] = Math.max(max[0], m.bbox[3]);
      max[1] = Math.max(max[1], m.bbox[2]);
    }
  };
  d.contents?.background?.forEach(r);
  d.contents?.area?.forEach(r);
  d.contents?.point?.forEach(r);
  if (min[0] > max[0]) {
    return null;
  }
  return [min, max];
};

export const polygonArea = (p) => {
  if (!p.length) {
    // Empty
    return 0;
  }
  if (typeof(p[0]) === 'number') {
    // Point; not applicable, really
    return 0;
  }
  if (typeof(p[0][0]) === 'number') {
    // Linear ring; base case
    let sum = 0;
    let [plon, plat] = p[p.length-1];
    p.forEach(([lon, lat]) => {
      sum += (plon * Math.sin(Math.radians(lat))) - (lon * Math.sin(Math.radians(plat)));
      plon = lon;
      plat = lat;
    });
    return 0.5 * earthRadius * earthRadius * sum;
  }
  if (typeof(p[0][0][0]) === 'number') {
    // Polygon; sum the rings
    let sum = 0;
    p.forEach((sub) => {
      sum += polygonArea(sub);
    });
    return Math.abs(sum);
  }
  // MultiPolygon; sum the polygons
  let sum = 0;
  p.forEach((sub) => {
    sum += polygonArea(sub);
  });
  return sum;
};

export const pointInPolygon = (pt, poly) => {
  if (!poly.length) {
    // Empty
    return false;
  }
  if (typeof(poly[0]) === 'number') {
    // Point; not applicable
    return false;
  }
  if (typeof(poly[0][0]) === 'number') {
    // Linear ring; base case
    let wn = 0;
    const [ax, ay] = pt;
    for (let i = 1; i < poly.length; i++) {
      const [px, py] = poly[i-1];
      const [qx, qy] = poly[i];
      if (py <= ay && qy > ay) {
        const x = px + (qx-px) * (ay-py) / (qy-py);
        if (x > ax) wn++;
      } else if (py > ay && qy <= ay) {
        const x = px + (qx-px) * (ay-py) / (qy-py);
        if (x > ax) wn--;
      }
    }
    return (wn & 1) !== 0;
  }
  // (Multi)polygon; check children
  let wn = 0;
  poly.forEach((sub) => {
    if (pointInPolygon(pt, sub)) {
      wn++;
    }
  });
  return (wn & 1) !== 0;
};

export const useEsc = (cb) => {
  useEffect(() => {
    const keydown = (ev) => {
      if (ev.keyCode === 27) {
        cb();
      }
    };
    document.addEventListener('keydown', keydown);
    return () => document.removeEventListener('keydown', keydown);
  }, [cb]);
};

// -*- mode: RJSX; js-indent-level: 2; -*-

import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  CircularProgress,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  TextField,
  IconButton,
  InputAdornment,
  Box,
  Fade,
} from '@material-ui/core';
import { Trans } from 'react-i18next';
import CopyIcon from './ContentCopyIcon';
import DeleteIcon from '@material-ui/icons/Delete';
import PersonIcon from '@material-ui/icons/Person';
import { shareEditStatus, shareEditDelete } from '../state/designs';

const useStyles = makeStyles((theme) => ({
  divider: {
    margin: '24px -24px 24px -24px',
  },
  status: {
    paddingLeft: '16px',
  },
}));

const ShareEditDialog = ({open, design, onClose}) => {
  const [url, setURL] = useState(null);
  const [currentEditors, setCurrentEditors] = useState([]);
  const [showCopied, setShowCopied] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (!open) {
      return;
    }
    setURL(null);
    setCurrentEditors(null);
    (async () => {
      const status = await shareEditStatus(design.id);
      if (status) {
        setURL(status.url);
        setCurrentEditors(status.editors);
      }
    })();
  }, [open, design?.id]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Trans>share-edit-title</Trans>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Trans>share-edit-instructions</Trans>
        </DialogContentText>
        {
          url ?
          <TextField
              fullWidth
              autoFocus
              onFocus={(ev) => ev.target.setSelectionRange(0, url.length)}
              inputProps={{
                readOnly: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton onClick={async () => {
                      let copied = false;
                      if (navigator.clipboard) {
                        try {
                          await navigator.clipboard.writeText(url);
                          copied = true;
                        }
                        catch (ex) {}
                      }
                      if (!copied) {
                        try {
                          document.execCommand('copy');
                          copied = true;
                        }
                        catch (ex) {}
                      }
                      if (copied) {
                        setShowCopied(true);
                        setTimeout(() => setShowCopied(false), 800);
                      }
                    }}>
                      <CopyIcon/>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={url}>
          </TextField> :
          <center>
            <CircularProgress/>
          </center>
        }
        {
          !!currentEditors?.length &&
          <>
            <Divider className={classes.divider}/>
            <DialogContentText>
              <Trans>share-edit-currently</Trans>
            </DialogContentText>
            <List>
              {currentEditors.map((e) => (
                <ListItem key={e.id}>
                  <ListItemAvatar>
                    <PersonIcon/>
                  </ListItemAvatar>
                  <ListItemText>
                    {e.name}
                  </ListItemText>
                  <ListItemSecondaryAction>
                    <IconButton onClick={() => {
                      shareEditDelete(design.id, e.id);
                      setCurrentEditors(currentEditors.filter((c) => c !== e));
                    }}>
                      <DeleteIcon/>
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </>
        }
      </DialogContent>
      <DialogActions>
        <Fade in={showCopied}>
          <DialogContentText className={classes.status}>
            <Trans>share-edit-copied</Trans>
          </DialogContentText>
        </Fade>
        <Box flexGrow={1}/>
        <Button onClick={onClose}>
          <Trans>close</Trans>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShareEditDialog;

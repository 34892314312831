// -*- mode: RJSX; js-indent-level: 2; -*-

import { Container, Paper, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';
import AppPage from './common/AppPage';

const useStyles = makeStyles(() => ({
  dialog: {
    padding: '2em',
  },
  content: {
    padding: '2em',
  },
}));

const LogoutDialog = () => {
  const classes = useStyles();

  return (
    <AppPage noPadding>
      <Container className={classes.dialog} maxWidth='sm'>
        <Paper className={classes.content} elevation={2}>
          <Typography variant='h6' gutterBottom>
            <Trans>logged-out</Trans>
          </Typography>
          <Typography variant='body1'>
            <Link to='/login'>
              <Trans>log-in-again</Trans>
            </Link>
          </Typography>
        </Paper>
      </Container>
    </AppPage>
  );
};

export default LogoutDialog;

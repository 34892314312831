// -*- mode: RJSX; js-indent-level: 2; -*-

import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Avatar,
  Typography,
  IconButton,
  CircularProgress,
  Box,
  Fab,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useTranslation, Trans } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { MapContainer, TileLayer } from 'react-leaflet';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import PersonIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';
import HiddenIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteDesignDialog from '../common/DeleteDesignDialog';
import ShareDesignDialog from '../common/ShareDesignDialog';
import { useDesignPreview, refreshPreview } from '../state/designs';
import { formatTimestring } from '../util';

const useStyles = makeStyles((theme) => ({
  placeholderTitle: {
    height: '1.5em',
    marginBottom: '0.5em',
  },
  placeholderSubheader: {
    height: '1em',
  },
  placeholderAvatar: {
    backgroundColor: '#888',
  },
  placeholderContentRow: {
    width: '100%',
    height: '1.5em',
    marginBottom: '0.5em',
  },

  loadingBackground: {
    animationName: '$loadingAnimation',
    animation: '2s linear 1s infinite forward forwards running',
    background: 'linear-gradient(-45deg, #eee 80%, #ddd 90%, #eee 100%)',
    backgroundSize: '200% 100%',
  },
  '@keyframes loadingAnimation': {
    '0%': {
      backgroundPosition: '100% 0',
    },
    '100%': {
      backgroundPosition: '-100% 0',
    },
  },

  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  map: {
    background: '#ccc',
    width: '100%',
    height: '300px',
    position: 'relative',
  },
  mediaWait: {
    color: theme.palette.primary.main,
    left: 'calc(50% - 37.5px)',
    top: '112.5px',
    position: 'absolute',
    zIndex: 1000000,
  },
  groupList: {
    marginTop: 0,
    marginBottom: 0,
  },
}));

const DesignCard = ({placeholder, welcome, design, onNewDesign}) => {
  const classes = useStyles();
  const preview = useDesignPreview(design?.id);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [shareDialog, setShareDialog] = useState(false);

  useEffect(() => {
    if (design?.id && preview === undefined) {
      dispatch(refreshPreview(design.id));
    }
  }, [design, preview, dispatch]);

  if (placeholder) {
    return (
      <Card elevation={8}>
        <CardHeader
            className={classes.placeholderHeader}
            avatar={<Avatar className={classes.placeholderAvatar}>&nbsp;</Avatar>}
            title=''
            subheader=''
            action={<IconButton disabled><EditIcon/></IconButton>}
            classes={{
              title: clsx(classes.placeholderTitle, classes.loadingBackground),
              subheader: clsx(classes.placeholderSubheader, classes.loadingBackground),
            }}/>
        <div className={classes.map}>
          <CircularProgress className={classes.mediaWait} size={75}/>
        </div>
        <CardContent>
          <div className={clsx(classes.placeholderContentRow, classes.loadingBackground)}/>
          <div className={clsx(classes.placeholderContentRow, classes.loadingBackground)}/>
          <div className={clsx(classes.placeholderContentRow, classes.loadingBackground)}/>
        </CardContent>
        <CardActions>
          <IconButton disabled>
            <VisibilityIcon/>
          </IconButton>
          <IconButton disabled>
            <DeleteIcon/>
          </IconButton>
        </CardActions>
      </Card>
    );
  }

  if (welcome) {
    return (
      <Card elevation={8}>
        <CardHeader title={t('welcome-title')} titleTypographyProps={{variant: 'h6'}}/>
        <CardContent>
          <Typography variant='body1' gutterBottom>
            <Trans>welcome-1</Trans>
          </Typography>
          <Typography variant='body1' gutterBottom>
            <Trans>welcome-2</Trans>
          </Typography>
          <Box display='flex' flexDirection='row'>
            <Box flexGrow={1} alignSelf='center'>
              <Typography variant='body1' className={classes.roomForFab}>
                <Trans>welcome-3</Trans>
              </Typography>
            </Box>
            <Box>
              <Fab color='primary' onClick={onNewDesign}>
                <AddIcon/>
              </Fab>
            </Box>
          </Box>
        </CardContent>
      </Card>
    );
  }

  return (
    <>
      <Card elevation={8}>
        <CardHeader
            className={classes.header}
            avatar={
              <Avatar className={classes.avatar}>
                {design.hidden ? <HiddenIcon/> : !design.shares.length ? <PersonIcon/> : <GroupIcon/>}
              </Avatar>
            }
            title={design.name}
            action={
              <IconButton onClick={() => navigate(design.id)}>
                <EditIcon/>
              </IconButton>
            }
            subheader={t('last-modified', {
              modified: formatTimestring(design.lastModified, {dateStyle: 'short', timeStyle: 'short'}),
            })}/>
        <Link to={design.id}>
          {
            preview?.url ? (
              <div className={classes.map}>
                <MapContainer
                    className={classes.map}
                    zoomControl={false}
                    dragging={false}
                    doubleClickZoom={false}
                    scrollWheelZoom={false}
                    center={[preview.interesting.center[1], preview.interesting.center[0]]}
                    zoom={preview.interesting.zoom}>
                  <TileLayer url={preview.base} minZoom={0} maxZoom={20}/>
                  <TileLayer url={preview.url} minZoom={0} maxZoom={20}/>
                </MapContainer>
                {
                  !!design.updating &&
                  <CircularProgress className={classes.mediaWait} size={75}/>
                }
              </div>
            ) : (
              <div className={classes.map}>
                <CircularProgress className={classes.mediaWait} size={75}/>
              </div>
            )
          }
        </Link>
        <CardContent>
          {
            design.ownership !== 'OWNER' &&
              <Typography variant='body1' gutterBottom>
                
              </Typography>
          }
          <Typography variant='body1'>
            {
              design.ownership !== 'OWNER' ?
                <Trans>design-shared-for-edit</Trans> :
                design.hidden ?
                <Trans>design-locked</Trans> :
                !design.shares.length ?
                <Trans>design-private</Trans> :
                design.shares.length === 1 ?
                t('design-shared-one', {group: design.shares[0].name || `(${t('unknown-group')})`}) :
                <Trans>design-shared-many</Trans>
            }
          </Typography>
          {
            !design.hidden && design.shares.length > 1 &&
              <ul className={classes.groupList}>
                {design.shares.map((sh) => <li key={sh.id}>{sh.name ?? `(${t('unknown-group')})`}</li>)}
              </ul>
          }
        </CardContent>
        <CardActions>
          {
            design.ownership === 'OWNER' &&
            <IconButton onClick={() => setShareDialog(true)}>
              <VisibilityIcon/>
            </IconButton>
          }
          <IconButton onClick={() => setConfirmDelete(true)}>
            <DeleteIcon/>
          </IconButton>
        </CardActions>
      </Card>
      <DeleteDesignDialog
          design={design}
          open={confirmDelete}
          sharedToMe={design.ownership !== 'OWNER'}
          onCancel={() => setConfirmDelete(false)}
          onDeleted={() => setConfirmDelete(false)}/>
      <ShareDesignDialog
          design={design}
          open={shareDialog}
          onClose={() => setShareDialog(false)}/>
    </>
  );
};

export default DesignCard;

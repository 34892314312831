// -*- mode: RJSX; js-indent-level: 2; -*-

import { useDispatch } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { deleteDesign } from '../state/designs';

const DeleteDesignDialog = ({design, open, tracking, sharedToMe, onCancel, onDeleted}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>
        <Trans>delete-design-p</Trans>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t(!sharedToMe ? 'delete-design-query' : 'delete-shared-design-query', {name: design.name})}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onCancel()}>
          <Trans>cancel</Trans>
        </Button>
        <Button onClick={() => {
          dispatch(deleteDesign(design.id, tracking));
          onDeleted();
        }}>
          <Trans>delete</Trans>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDesignDialog;
